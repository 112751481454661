import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./ChecklistTeacherAbschluss.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const ChecklistTeacherAbschluss = () => {
  const [teacherChecklist, setTeacherChecklist] = useState([]);
  const [abschlussCardsAbsch, setAbschlussCardsAbsch] = useState([]);
  const [abschlussTitleAbsch, setAbschlussTitleAbsch] = useState([]);
  const [abschlussValueAbsch, setAbschlussValueAbsch] = useState([]);
  const [abschlussDataAbsch, setAbschlussDataAbsch] = useState([]);

  const checklistTeacher = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");

    const findPlinDiv = () => {
      const allPlink = document.querySelectorAll(".plink");
      const fullData = [];
      allPlink.forEach((element) => {
        let data = element.innerHTML.match(/^(.*?)<input/);
        if (data && data.length >= 2) {
          fullData.push(data[1].trim());
        }
      });

      const plinkData = document.querySelectorAll(".plink input");
      const mainDatas = [];
      plinkData.forEach((element) => {
        !mainDatas.includes(element.getAttribute("data-roomname")) &&
          mainDatas.push(element.getAttribute("data-roomname"));
      });
      setAbschlussCardsAbsch(mainDatas);

      let current = 0;
      const titleArray = [];
      const valueArray = [];
      plinkData.forEach((element, index) => {
        if (element.getAttribute("data-roomname") === mainDatas[current]) {
          if (valueArray[current]) {
            titleArray[current].push(fullData[index]);
            valueArray[current].push(element.value);
          } else {
            titleArray.push([fullData[index]]);
            valueArray.push([element.value]);
          }
        } else {
          current += 1;
          if (valueArray[current + 1]) {
            titleArray[current + 1].push(fullData[index]);
            valueArray[current + 1].push(element.value);
          } else {
            titleArray.push([fullData[index]]);
            valueArray.push([element.value]);
          }
        }
      });
      setAbschlussTitleAbsch(titleArray);
      setAbschlussValueAbsch(valueArray);
    };

    await axios
      .post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getTeacherAbsChecklistById&teacher_id=170&location=ZH&status=1`
      )
      .then((resp) => {
        setTeacherChecklist(resp.data);
        findPlinDiv();
      });
  };

  useEffect(() => {
    checklistTeacher();
  }, [teacherChecklist]);

  const submitData = (e) => {
    e.preventDefault();

    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const formData = new FormData();
    formData.append("point_type", "abschluss");
    formData.append("teacher_id", localStorage.getItem("teacher_id_student"));
    abschlussDataAbsch.map((listID) => {
      formData.append("option_list[]", listID);
    });

    if (abschlussDataAbsch.length < 1) {
      toast("Please Select Checklist");
    } else {
      axios
        .post(
          `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=addTeacherChecklistMultiple&status=1`,
          formData
        )
        .then((resp) => {
          if (resp.status) {
            toast("Checklist Added Successfully");
            setAbschlussDataAbsch([]);
          }
        });
    }
  };

  return (
    <div className="abschluss_section">
      <div
        className="dataToHide"
        dangerouslySetInnerHTML={{ __html: teacherChecklist }}
      ></div>
      <ToastContainer />
      <div className="container-fluid">
        <div className="abschluss">
          <div className="user_detail">
            <div className="abschluss_title">
              <h2>Abschluss</h2>
            </div>
          </div>
          <div className="abschluss">
            <div className="abschluss_inner">
              <div className="abschluss_cards row">
                {abschlussCardsAbsch?.map(
                  (item, index) =>
                    abschlussValueAbsch[index].length > 1 && (
                      <div
                        className="abschluss_card col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                        key={index + abschlussValueAbsch[index] + item}
                      >
                        <div className="abschluss_card_inner">
                          <form className="row">
                            <div className="col-12">
                              <div className="form-check abschluss_card_title">
                                <h2>{item}</h2>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="gridCheck"
                                  checked={abschlussValueAbsch[index]
                                    .map((checkData, checkIndex) => {
                                      if (
                                        abschlussDataAbsch.includes(
                                          checkData
                                        ) &&
                                        checkData !== ""
                                      ) {
                                        return true;
                                      }
                                      if (
                                        !abschlussDataAbsch.includes(
                                          checkData
                                        ) &&
                                        checkData !== ""
                                      ) {
                                        return false;
                                      }
                                      if (checkData === "") {
                                        return true;
                                      }
                                    })
                                    .every((element) => element === true)}
                                  onChange={() => {
                                    const newArray = abschlussDataAbsch?.filter(
                                      (element) =>
                                        !abschlussValueAbsch[index].includes(
                                          element
                                        ) && element
                                    );

                                    if (
                                      abschlussValueAbsch[index]
                                        .map((checkData, checkIndex) => {
                                          if (
                                            abschlussDataAbsch.includes(
                                              checkData
                                            ) &&
                                            checkData !== ""
                                          ) {
                                            return true;
                                          }
                                          if (
                                            !abschlussDataAbsch.includes(
                                              checkData
                                            ) &&
                                            checkData !== ""
                                          ) {
                                            return false;
                                          }
                                          if (checkData === "") {
                                            return true;
                                          }
                                        })
                                        .every((element) => element === true)
                                    ) {
                                      setAbschlussDataAbsch(newArray);
                                    } else {
                                      setAbschlussDataAbsch(newArray);
                                      setAbschlussDataAbsch(
                                        abschlussDataAbsch.concat(
                                          abschlussValueAbsch[index]
                                        )
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="abschluss_detail">
                                {abschlussTitleAbsch[index]?.map(
                                  (titleData, titleIndex) =>
                                    titleIndex !== 0 && (
                                      <div
                                        className="form-check"
                                        key={titleIndex}
                                      >
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="gridCheck"
                                          checked={abschlussDataAbsch.includes(
                                            abschlussValueAbsch[index][
                                              titleIndex
                                            ]
                                          )}
                                          onChange={() => {
                                            if (
                                              abschlussDataAbsch.includes(
                                                abschlussValueAbsch[index][
                                                  titleIndex
                                                ]
                                              )
                                            ) {
                                              setAbschlussDataAbsch(
                                                abschlussDataAbsch.filter(
                                                  (item) => {
                                                    return (
                                                      abschlussValueAbsch[
                                                        index
                                                      ][titleIndex] !== item
                                                    );
                                                  }
                                                )
                                              );
                                            } else {
                                              setAbschlussDataAbsch([
                                                ...abschlussDataAbsch,
                                                abschlussValueAbsch[index][
                                                  titleIndex
                                                ],
                                              ]);
                                            }
                                          }}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="gridCheck"
                                        >
                                          {titleData}
                                        </label>
                                      </div>
                                    )
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    )
                )}
                <div className="abschluss_card col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="submit_btn">
                    <Link
                      className="btn btn_primary"
                      href="#"
                      onClick={submitData}
                    >
                      SUBMIT
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChecklistTeacherAbschluss;
