import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./Exercise.scss";
import { ToastContainer, toast } from "react-toastify";

const Exercise = () => {
  const [exerciseData, setExerciseData] = useState({});
  const [exerciseVideoData, setExerciseVideoData] = useState();
  const [excerciseDate, setExcerciseDate] = useState("");
  const [excerciseActive, setExcerciseActive] = useState(false);

  const getExerciseData = async () => {
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("session_user_id"));

    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");

    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getActivatedExercise&status=1`;

    await axios.post(api, formData).then((resp) => {
      setExerciseData(resp.data[0]);
      if (resp.data[0].status === "1") {
        setExcerciseActive(true);
        setExcerciseDate(resp.data[0].start_date);
      } else {
        setExcerciseActive(false);
      }
    });
  };

  const getExerciseVideo = async () => {
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("session_user_id"));
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getExerciseVideoList&status=1`;

    await axios.post(api, formData).then((resp) => {
      setExerciseVideoData(resp.data);
    });
  };

  const activeExercise = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("session_user_id"));
    formData.append("date", excerciseDate);
    formData.append("active_status", excerciseActive ? 1 : 0);
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=setActivateExercise&status=1`;

    await axios.post(api, formData).then((resp) => {
      getExerciseData();
      getExerciseVideo();
      toast("Active Date For Excercise Added Successfully");
    });
  };

  const exerciseCompletion = async (days, exercise_id, activated_id) => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("session_user_id"));
    formData.append("days", days);
    formData.append("exercise_id", exercise_id);
    formData.append("activate_id", activated_id);
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=setExerciseCompletion&status=1`;

    await axios.post(api, formData).then((resp) => {
      getExerciseData();
      getExerciseVideo();
      toast(`Your Exercise done for the Day ${days}`);
    });
  };
  useEffect(() => {
    getExerciseData();
    getExerciseVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="exercise_section">
      <div className="container-fluid">
        <ToastContainer />
        <div className="user_detail">
          <div className="exercise_title">
            <h2>EXERCISE</h2>
          </div>
        </div>
        <div className="exercise_listing">
          <div className="exercise_inner">
            <div className="exercise_date">
              <form className="row">
                <div className="col-12">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                      checked={excerciseActive}
                      onChange={(e) => {
                        setExcerciseActive(!excerciseActive);
                      }}
                    />
                    <label className="form-check-label" htmlFor="gridCheck">
                      Active Exercise Tool
                    </label>
                  </div>
                </div>
                <div className="form-input-column col-12">
                  <div className="form-input">
                    <input
                      type="date"
                      className="form-control"
                      id="inputdate"
                      value={excerciseDate}
                      onChange={(e) => {
                        setExcerciseDate(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="active-btn-column col-12">
                  <div className="active_btn">
                    <Link
                      type="submit"
                      className="btn btn_primary"
                      onClick={() =>
                        exerciseData.length < 9
                          ? toast("Please Select Start Date")
                          : activeExercise()
                      }
                    >
                      Active
                    </Link>
                  </div>
                </div>
              </form>
            </div>

            {exerciseData.status === "1" && (
              <>
                <div className="exercise_card">
                  <div className="exercise_name">
                    {exerciseVideoData !== undefined && (
                      <h2>
                        <span className="exercise_type">Exercise Type :</span>{" "}
                        <span>
                          {exerciseVideoData?.video[0]?.video_exercise_title}
                        </span>
                      </h2>
                    )}
                  </div>
                  <div className="exercise_days">
                    <form className="row">
                      {exerciseVideoData !== undefined &&
                        Object.values(
                          exerciseVideoData?.video[0]?.video_date_list
                        )?.map((item, index) => (
                          <div className="exercise_day_column col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="exercise_day">
                              <h3>{item[0].days.split(" ")[2]}</h3>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name={`flexRadioDefault${index}`}
                                  id={`flexRadioDefault${index}`}
                                  checked={item[0].status !== "0"}
                                  onClick={() => {
                                    exerciseCompletion(
                                      index + 1,
                                      exerciseVideoData?.video[0]
                                        ?.video_exercise_id,
                                      exerciseVideoData?.activate_id
                                    );
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`flexRadioDefault${index}`}
                                >
                                  Day {index + 1}
                                </label>
                              </div>
                            </div>
                          </div>
                        ))}
                    </form>
                  </div>
                </div>

                <div className="exercise_video">
                  <div className="exercise_video_inner">
                    {exerciseVideoData !== undefined && (
                      <iFrame
                        src={`https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=6362c9886d97d&login_user_id=227&login_action_type=student&f=getVimeoAuth&code=${
                          exerciseVideoData?.video[0]?.video_exercise_url.match(
                            /\/\/player\.vimeo\.com\/video\/(\d+)/
                          )[1]
                        }&status=1`}
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                        Style="width:330px;height:50vh"
                        webkitallowfullscreen=""
                      ></iFrame>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Exercise;
