import React, { useEffect, useState } from "react";
import axios from "axios";
import "./TeacherCourseDetail.scss";
import { useParams } from "react-router-dom";

const TeacherCourseDetail = () => {
  const [teacherCourseData, setTeacherCourseData] = useState([]);
  const [teacherTableHeader, setTeacherTableHeader] = useState([]);
  const [teacherTableBody, setTeacherTableBody] = useState([]);

  const { option2 } = useParams();

  const getTeacherDetailData = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const school_id = localStorage.getItem("session_school_id");
    const user_id = localStorage.getItem("session_user_id");

    const parts = option2.split("-");
    const course_id = parseInt(parts[0]);
    const level_id = parseInt(parts[1].split(" ")[1]);

    const getAllHeader = () => {
      const allHeader = document.querySelectorAll("thead");
      let headerData = [];
      allHeader.forEach((header) => {
        headerData.push(header.innerHTML);
      });
      setTeacherTableHeader(headerData);
    };

    const getAllBody = () => {
      const allBody = document.querySelectorAll("tbody");
      let bodyData = [];
      allBody.forEach((header) => {
        bodyData.push(header.innerHTML);
      });
      setTeacherTableBody(bodyData);
    };

    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getteacherstudentpss&school_id=${school_id}&level_id=${level_id}&course_id=${course_id}&user_id=${user_id}&status=1`;
    await axios.post(api).then((resp) => {
      setTeacherCourseData(resp.data);
      getAllHeader();
      getAllBody();
    });
  };

  useEffect(() => {
    getTeacherDetailData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pause_cancel_section">
      {Object.values(teacherCourseData).map((data, index) => (
        <div
          className="dataToHide"
          ket={index}
          dangerouslySetInnerHTML={{
            __html: data,
          }}
        ></div>
      ))}
      <div className="container-fluid">
        <div className="user_detail">
          <div className="pause_cancel_title">
            <h2>PAUSE/CANCEL/START</h2>
          </div>
        </div>
        <div className="pause_cancel_inner">
          {teacherTableHeader.map((item, index) => (
            <div className="pause_cancel_table" key={index}>
              <div className="table-responsive">
                <div className="table-group-divider bg_primary text-center">
                  {Object.keys(teacherCourseData)[index]}
                </div>
                <table className="table table-bordered">
                  <thead
                    dangerouslySetInnerHTML={{
                      __html: item,
                    }}
                  ></thead>
                  <tbody
                    className="table-group-divider"
                    dangerouslySetInnerHTML={{
                      __html: teacherTableBody[index],
                    }}
                  ></tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeacherCourseDetail;
