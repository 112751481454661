import React from "react";
import { Link } from "react-router-dom";
import cource_overview_1 from "../../../../assets/images/icons/cource-overview-1.svg";
import my_task from "../../../../assets/images/icons/my-task.svg";
import salary from "../../../../assets/images/icons/salary.svg";
import checklist_teacher from "../../../../assets/images/icons/checklist-teacher.svg";
import department_task from "../../../../assets/images/icons/department-task.svg";
import check_list from "../../../../assets/images/icons/check-list.svg";
import performance from "../../../../assets/images/icons/performance.svg";
import protocol from "../../../../assets/images/icons/protocol.svg";
import "./TeacherBlocks.scss";

const TeacherBlocks = () => {
  return (
    <div className="teachers_blocks">
      <div className="container-fluid">
        <div className="teachers_blocks_inner">
          <div className="teachers_blocks_group">
            <div className="row">
              <div className="teachers_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                <Link
                  className="teachers_block_inner"
                  to="/Home/profile/teacher/teacherCourse"
                >
                  <div className="teachers_block_icon">
                    <img
                      className="img-fluid svg_img"
                      src={cource_overview_1}
                      alt="cource_overview_1"
                    />
                  </div>
                  <div className="teachers_block_title">
                    <h2>Teacher Course</h2>
                  </div>
                </Link>
              </div>
              <div className="teachers_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                <Link
                  className="teachers_block_inner"
                  to="/Home/profile/teacher/myTask"
                >
                  <div className="teachers_block_icon">
                    <img
                      className="img-fluid svg_img"
                      src={my_task}
                      alt="my_task"
                    />
                  </div>
                  <div className="teachers_block_title">
                    <h2>My Task</h2>
                  </div>
                </Link>
              </div>
              <div className="teachers_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                <Link
                  className="teachers_block_inner"
                  to="/Home/profile/teacher/departmentTask"
                >
                  <div className="teachers_block_icon">
                    <img
                      className="img-fluid svg_img"
                      src={department_task}
                      alt="department_task"
                    />
                  </div>
                  <div className="teachers_block_title">
                    <h2>Department Task</h2>
                  </div>
                </Link>
              </div>
              <div className="teachers_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                <Link
                  className="teachers_block_inner"
                  to="/Home/profile/teacher/addSalary"
                >
                  <div className="teachers_block_icon">
                    <img
                      className="img-fluid svg_img"
                      src={salary}
                      alt="salary"
                    />
                  </div>
                  <div className="teachers_block_title">
                    <h2>Add Salary</h2>
                  </div>
                </Link>
              </div>
              <div className="teachers_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                <Link
                  className="teachers_block_inner"
                  to="/Home/profile/teacher/checklistTeacher"
                >
                  <div className="teachers_block_icon">
                    <img
                      className="img-fluid svg_img"
                      src={checklist_teacher}
                      alt="checklist_teacher"
                    />
                  </div>
                  <div className="teachers_block_title">
                    <h2>Checklist Teacher</h2>
                  </div>
                </Link>
              </div>
              <div className="teachers_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                <Link
                  className="teachers_block_inner"
                  to="/Home/profile/teacher/checklistCeo"
                >
                  <div className="teachers_block_icon">
                    <img
                      className="img-fluid svg_img"
                      src={check_list}
                      alt="check_list"
                    />
                  </div>
                  <div className="teachers_block_title">
                    <h2>Checklist CEO</h2>
                  </div>
                </Link>
              </div>
              <div className="teachers_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                <Link
                  className="teachers_block_inner"
                  to="/Home/profile/teacher/teacherPerformance"
                >
                  <div className="teachers_block_icon">
                    <img
                      className="img-fluid svg_img"
                      src={performance}
                      alt="performance"
                    />
                  </div>
                  <div className="teachers_block_title">
                    <h2>Teacher Performance</h2>
                  </div>
                </Link>
              </div>

              <div className="teachers_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                <Link
                  className="teachers_block_inner"
                  to="/Home/profile/teacher/protokoll"
                >
                  <div className="teachers_block_icon">
                    <img
                      className="img-fluid svg_img"
                      src={protocol}
                      alt="protokoll"
                    />
                  </div>
                  <div className="teachers_block_title">
                    <h2>Protokoll</h2>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherBlocks;
