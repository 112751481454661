import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const SignInPrivateRoutes = () => {
  const auth = {
    token:
      localStorage.getItem("session_user_status") === "Active" ? false : true,
  };
  return auth.token ? <Outlet /> : <Navigate to="/Home/dashboard" />;
};

export default SignInPrivateRoutes;
