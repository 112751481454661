import React, { useEffect, useState } from "react";
import axios from "axios";
import "./TeacherCourse.scss";
import { Link } from "react-router-dom";

const TeacherCourse = () => {
  const [data, setData] = useState(null);
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const conditionalDays = ["so", "mo", "di", "mi", "do", "fr", "sa"];

  const tableDetails = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const user_id = localStorage.getItem("session_user_id");

    const formData = new FormData();
    formData.append("user_id", user_id);

    await axios
      .post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getCourseByTeacher&status=1`,
        formData
      )
      .then((resp) => {
        setData(resp.data);
      });
  };

  useEffect(() => {
    tableDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="teacher_course_section">
      <div className="container-fluid">
        <div className="teacher_course">
          <div className="user_detail">
            <div className="teacher_course_title">
              <h2>TEACHER COURSE</h2>
            </div>
          </div>
          <div className="teacher_course">
            {data !== null &&
              Object.values(data)?.map(
                (item, index) =>
                  typeof item === "object" && (
                    <div
                      className="teacher_course_inner"
                      key={`${index}-index`}
                    >
                      <div className="teacher_course_card_status">
                        <ul
                          className="nav nav-pills mb-3"
                          id="pills-tab"
                          role="tablist"
                        >
                          {Object.values(item)?.map((itemDay, indexDay) => (
                            <li
                              className="nav-item"
                              role="presentation"
                              key={`${index}-${indexDay}`}
                            >
                              <Link
                                href="#"
                                className={`nav-link ${
                                  indexDay === 0 && "active"
                                }`}
                                id={`pills-zh-salsa-${indexDay}-tab`}
                                data-bs-toggle="pill"
                                data-bs-target={`#pills-zh-salsa-${indexDay}`}
                                type="button"
                                role="tab"
                                aria-controls={`pills-zh-salsa-${indexDay}`}
                                aria-selected="true"
                              >
                                {
                                  days[
                                    conditionalDays.indexOf(
                                      Object.keys(item)[indexDay]
                                    )
                                  ]
                                }
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                      {Object.values(item)?.map((itemDay, indexDay) => (
                        <div
                          className={`tab-pane fade show ${
                            indexDay === 0 && "active"
                          }`}
                          id={`pills-zh-salsa-${indexDay}`}
                          role="tabpanel"
                          aria-labelledby={`pills-zh-salsa-${indexDay}-tab`}
                          tabIndex={`${indexDay}`}
                          key={`${index}-${indexDay}-tab`}
                        >
                          <div className="teacher_course_cards row">
                            {Object.values(itemDay)?.map(
                              (insideDay, insideDayIndex) =>
                                Object.values(insideDay)?.map(
                                  (mainValues, valuesIndex) => (
                                    <Link
                                      to={`/Home/profile/teacherCourseDetail/${mainValues.course_id}-${mainValues?.Level_id}`}
                                      className="teacher_course_card col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                      key={`${index}-${indexDay}-${insideDayIndex}-${valuesIndex}`}
                                    >
                                      <div className="teacher_course_card_inner">
                                        <div className="teacher_course_card_title">
                                          <h2>
                                            {" "}
                                            {
                                              Object.keys(itemDay)[
                                                insideDayIndex
                                              ]
                                            }
                                          </h2>
                                        </div>
                                        <div className="teacher_course_detail">
                                          <ul className="teacher_course_list">
                                            <li className="teacher_course_item">
                                              <span>Title :</span>
                                              <span>
                                                {mainValues?.course_title}
                                              </span>
                                            </li>
                                            <li className="teacher_course_item">
                                              <span>Advanced Level :</span>
                                              <span>
                                                {mainValues?.Level_id}
                                              </span>
                                            </li>
                                            <li className="teacher_course_item">
                                              <span>Starting Time :</span>
                                              <span>
                                                {mainValues?.class_start_time}
                                              </span>
                                            </li>
                                            <li className="teacher_course_item">
                                              <span>Ending Time :</span>
                                              <span>
                                                {mainValues?.class_end_time}
                                              </span>
                                            </li>
                                            <li className="teacher_course_item">
                                              <span>Room :</span>
                                              <span>
                                                {mainValues?.course_room}
                                              </span>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </Link>
                                  )
                                )
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherCourse;
