import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SelectSchool from "./pages/SelectSchool/SelectSchool";
import SignIn from "./pages/SignIn/SignIn";
import Home from "./pages/Home/Home";
import PrivateRoutes from "./utils/PrivateRoutes";
import SignInPrivateRoutes from "./utils/SignInPrivateRoutes";
import Forgot from "./pages/Forgot/Forgot";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<SignInPrivateRoutes />}>
          <Route path="/" element={<SelectSchool />} />
          <Route path="/SignIn" element={<SignIn />} />
          <Route path="/forgot" element={<Forgot />} />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route path="/Home/:option" element={<Home />} />
          <Route path="/Home/:option/:option1" element={<Home />} />
          <Route path="/Home/:option/:option1/:option2" element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
