import React, { useEffect, useState } from "react";
import "./Chat.scss";
import axios from "axios";
import attechment from "../../../assets/images/icons/attechment.svg";
import send from "../../../assets/images/icons/msg-icon.png";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";

const Chat = () => {
  const [chatData, setChatData] = useState([]);
  const [message, setMessage] = useState("");
  const { option2 } = useParams();

  const getChatInnerData = async () => {
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("session_user_id"));
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=chatlist&user_id=${login_user_id}&group_id=${option2}&last_id=&req_from=app&status=1`;

    await axios.post(api, formData).then((resp) => {
      setChatData(resp.data);
    });
  };

  const sendMessage = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=send_message&message=${message}&group_id=${option2}&user_id=${login_user_id}&is_ceo=${localStorage.getItem(
      "session_user_is_ceo"
    )}&status=1`;
    await axios.post(api).then((resp) => {
      setMessage("");
      getChatInnerData();
    });
  };
  useEffect(() => {
    getChatInnerData();
  });
  return (
    <div className="position-relative">
      <ToastContainer />
      <div className="chat_section">
        <div className="container-fluid">
          <div className="chat_title">
            <h2>{chatData?.chat_name}</h2>
          </div>
          <div className="chat_inner">
            <div
              dangerouslySetInnerHTML={{
                __html: chatData?.html,
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className="chat_form">
        <div className="container-fluid">
          <div className="chat_form_inner">
            <div className="form">
              <input
                type="text"
                className="form-control"
                id="inputText"
                placeholder="Enter your message here"
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
              <div className="attachment_btn_part">
                <div className="input-group">
                  <label
                    className="input-group-text"
                    htmlFor="inputGroupFile01"
                  >
                    <img src={attechment} className="img-fluid" alt="" />
                  </label>
                  <input
                    type="file"
                    className="form-control d-none"
                    id="inputGroupFile01"
                  />
                </div>
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    e.preventDefault();

                    if (message === "") {
                      toast("Message Is Empty.");
                    } else {
                      sendMessage();
                    }
                  }}
                >
                  <img src={send} className="img-fluid" alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;
