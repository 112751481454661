import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Forgot.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import loginBg from "../../assets/images/logo/Group 1.png";

const Forgot = () => {
  const [userType, setUserType] = useState(null);
  const [userValue, setUserValue] = useState(null);
  const [userToogle, setUserToogle] = useState(false);
  const [email, setEmail] = useState("");

  const navigate = useNavigate();

  const loginFunction = async (e) => {
    e.preventDefault();
    const notifyInvalidCredantial = () => toast("Invalid Credantial");
    const notifySchoolSelect = () => toast("Please Select School");
    const notifyAllFeild = () => toast("All Feilds Are Mendatory");
    const notifySuccess = () =>
      toast("Successfully Mail Sent For Forgot Password");

    if (!localStorage.getItem("session_school_id")) {
      notifySchoolSelect();
      navigate("/");
    } else if (email === null || userType === null) {
      notifyAllFeild();
    } else {
      await axios
        .post(
          `https://www.api.salsaworldstandardclass.com/function.php/function.php/?login_cookie=null&login_user_id=null&login_action_type=null&f=forgetPassword&email=${email}&action_type=${
            userType === "student_login"
              ? "student_forgot_pwd"
              : "teacher_forgot_pwd"
          }&school=${localStorage.getItem("session_school_id")}&status=1`
        )
        .then((respnse) => {
          if (respnse.data.status === false) {
            notifyInvalidCredantial();
            setEmail("");
            setUserType(null);
            setUserValue(null);
            setUserToogle(false);
          }
          if (respnse.data.status === true) {
            notifySuccess();
            setEmail("");
            setUserType(null);
            setUserValue(null);
            setUserToogle(false);
          }
        });
    }
  };

  if (localStorage.getItem("session_user_status") === "Active") {
    navigate("/Home/dashboard");
  }

  return (
    <>
      <section className="login_section">
        <ToastContainer />
        <div className="container">
          <div className="login_row row align-items-center">
            <div className="login_img_column">
              <div className="login_img">
                <img className="img img-fluid" src={loginBg} alt="mainImg" />
              </div>
            </div>
            <div className="login_content_column">
              <div className="signInHeading">
                <h1>Salsa World Standard Class</h1>
              </div>
              <form className="login_form" onSubmit={loginFunction}>
                <div className="custom-select">
                  <button
                    className={`btn dropdown-toggle ${userToogle && "show"}`}
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={() => {
                      setUserToogle(!userToogle);
                    }}
                  >
                    {userValue === null ? "Select User" : userValue}
                  </button>
                  <ul className={`dropdown-menu ${userToogle && "show"}`}>
                    <li
                      onClick={() => {
                        setUserType("student_login");
                        setUserValue("STUDENT");
                      }}
                    >
                      <Link
                        className="dropdown-item"
                        onClick={() => {
                          setUserToogle(false);
                        }}
                      >
                        STUDENT
                      </Link>
                    </li>
                    <li
                      onClick={() => {
                        setUserType("teacher_login");
                        setUserValue("TEACHER");
                      }}
                    >
                      <Link
                        className="dropdown-item"
                        to="#"
                        onClick={() => {
                          setUserToogle(false);
                        }}
                      >
                        TEACHER
                      </Link>
                    </li>
                  </ul>
                </div>
                <input
                  type="text"
                  className="login_input"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder="Email"
                />
                <div className="login_btn">
                  <button className="btn btn_primary" type="submit">
                    Submit
                  </button>
                </div>
              </form>
              <div className="changeSchool">
                <div className="schoolChangeLink">
                  <Link to="/">Change School</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="copyright">
        <p>© 2023 SALSA WORLD STANDARD CLASS</p>
      </section>
    </>
  );
};

export default Forgot;
