import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./AddSalary.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const AddSalary = () => {
  const [kursList, setKursList] = useState([]);
  const [kursToogle, setKursToogle] = useState(false);
  const [kursToogleValue, setKursToogleValue] = useState(null);
  const [salaryDayToogle, setSalaryDayToogle] = useState(false);
  const [salaryDayToogleValue, setSalaryDayToogleValue] = useState(null);
  const [selectDate, setSelectDate] = useState("");
  const [salaryNote, setSalaryNote] = useState("");

  const getKurslist = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const formData = new FormData();
    formData.append("school_id", localStorage.getItem("session_school_id"));
    await axios
      .post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getSalaryPage&status=1`,
        formData
      )
      .then((resp) => {
        setKursList(resp.data.c1);
      });
  };

  const addList = async (e) => {
    e.preventDefault();

    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");

    const formData = new FormData();
    formData.append(
      "form_data",
      `user_id=${localStorage.getItem(
        "session_user_id"
      )}&course=${kursToogleValue}&protocol_date=${selectDate}&salary_day=${salaryDayToogleValue}&salary_note=${salaryNote}`
    );

    if (kursToogleValue === null) {
      toast("Please Select Course");
    }

    if (selectDate.length < 9) {
      toast("Please Select Date");
    }

    if (salaryDayToogleValue === null) {
      toast("Please Select Day");
    }

    if (salaryNote.length < 1) {
      toast("Please Add Something In Salary Note");
    }

    if (
      kursToogleValue !== null &&
      selectDate.length > 9 &&
      salaryDayToogleValue !== null &&
      salaryNote.length > 0
    ) {
      await axios
        .post(
          `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=addSalary&status=1`,
          formData
        )
        .then((resp) => {
          if (resp.status === 200) {
            toast("Salary added successfully");
            setKursToogleValue(null);
            setSelectDate("");
            setSalaryDayToogleValue(null);
            setSalaryNote("");
          }
        });
    }
  };
  useEffect(() => {
    getKurslist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="salary_section">
      <ToastContainer />
      <div className="container-fluid">
        <div className="user_detail">
          <div className="salary_title">
            <h2>Salary</h2>
          </div>
        </div>
        <div className="salary_card">
          <div className="salary_card_inner">
            <div className="salary_form">
              <form className="row">
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                  <label htmlFor="inputKurs" className="form-label">
                    Kurs List
                  </label>
                  <div className="type-select">
                    <button
                      className={`btn dropdown-toggle4 ${kursToogle && "show"}`}
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => {
                        setKursToogle(!kursToogle);
                        setSalaryDayToogle(false);
                      }}
                    >
                      {kursToogleValue === null ? "Select" : kursToogleValue}
                    </button>
                    <ul className={`dropdown-menu ${kursToogle && "show"}`}>
                      {kursList?.map((item, index) => (
                        <li key={index}>
                          <Link
                            key={index}
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              setKursToogle(false);
                              setKursToogleValue(item.course_title);
                            }}
                          >
                            {item.course_title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                  <label htmlFor="inputSalaryday" className="form-label">
                    Salary Day
                  </label>
                  <div className="day-select">
                    <button
                      className={`btn dropdown-toggle5 ${
                        salaryDayToogle && "show"
                      }`}
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => {
                        setSalaryDayToogle(!salaryDayToogle);
                        setKursToogle(false);
                      }}
                    >
                      {salaryDayToogleValue === null
                        ? "Select Day"
                        : salaryDayToogleValue}
                    </button>
                    <ul
                      className={`dropdown-menu ${salaryDayToogle && "show"}`}
                    >
                      <li>
                        <Link
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            setSalaryDayToogle(false);
                            setSalaryDayToogleValue("sunday");
                          }}
                        >
                          Sunday
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            setSalaryDayToogle(false);
                            setSalaryDayToogleValue("monday");
                          }}
                        >
                          Monday
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            setSalaryDayToogle(false);
                            setSalaryDayToogleValue("tuesday");
                          }}
                        >
                          Tuesday
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            setSalaryDayToogle(false);
                            setSalaryDayToogleValue("wednesday");
                          }}
                        >
                          Wednesday
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            setSalaryDayToogle(false);
                            setSalaryDayToogleValue("thursday");
                          }}
                        >
                          Thursday
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            setSalaryDayToogle(false);
                            setSalaryDayToogleValue("friday");
                          }}
                        >
                          Friday
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          href="#"
                          onClick={() => {
                            setSalaryDayToogle(false);
                            setSalaryDayToogleValue("saturday");
                          }}
                        >
                          Saturday
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <label htmlFor="inputDate" className="form-label">
                    Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="inputDate"
                    onChange={(e) => {
                      setSelectDate(e.target.value);
                    }}
                    value={selectDate}
                  />
                </div>

                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <label htmlFor="inputNote" className="form-label">
                    Salary Notes *
                  </label>
                  <textarea
                    className="form-control"
                    id="inputNote"
                    rows="4"
                    value={salaryNote}
                    onChange={(e) => {
                      setSalaryNote(e.target.value);
                    }}
                  ></textarea>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <Link
                    type="submit"
                    className="btn btn_primary"
                    onClick={addList}
                  >
                    Submit
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSalary;
