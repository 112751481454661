import { Link, useParams } from "react-router-dom";
import home from "../../assets/images/icons/home.svg";
import be_happy from "../../assets/images/icons/be-happy.svg";
import profile from "../../assets/images/icons/profile.svg";
import course_management from "../../assets/images/icons/course_management.svg";
import message from "../../assets/images/icons/message.svg";
import event from "../../assets/images/icons/event.svg";
import media from "../../assets/images/icons/media.svg";
import faq from "../../assets/images/icons/faq.svg";
import language from "../../assets/images/icons/language.svg";
import logout from "../../assets/images/icons/logout.svg";
import "./Sidebar.scss";

const Sidebar = ({ toogle, setToogle }) => {
  const { option } = useParams();
  const { option1 } = useParams();
  const keysToRemove = [
    "is_pause_student",
    "session_user_device_id",
    "session_user_level_id",
    "session_admin_name",
    "session_course_id_detail_page_forvideo",
    "session_user_updated_date",
    "session_user_country",
    "session_setting_show_pic_inside_group",
    "session_user_lastname",
    "message_absent",
    "session_language_array",
    "session_user_login_cookie",
    "session_user_package_description",
    "session_user_renewal_notify",
    "session_user_selected_type",
    "session_user_status",
    "session_admin_email",
    "session_setting_newsletter",
    "session_user_contact",
    "session_user_password",
    "session_user_payment_status",
    "session_user_address",
    "session_user_package_expired_date",
    "session_user_is_ceo",
    "session_user_school_email",
    "session_user_state",
    "session_setting_show_online_friends",
    "session_user_sender_id",
    "session_user_city",
    "session_user_id",
    "session_user_email",
    "session_user_profile_pic",
    "session_school_image",
    "session_student_teacher",
    "session_user_created_date",
    "session_premium_status_page",
    "session_setting_event",
    "session_admin_id",
    "session_setting_account",
    "session_user_package_cost",
    "session_user_gender",
    "session_user_school_name",
    "teacher_id_student",
    "session_user_type",
    "session_setting_notification",
    "session_language",
    "session_user_zipcode",
    "is_external_teacher",
    "review",
    "session_video_id_for_video_detail_page",
    "session_user_firstname",
    "session_school_id",
    "session_user_api_id",
    "session_user_package_duration",
    "session_school_type",
    "session_user_package_id",
    "session_user_package_activated_date",
    "session_user_profile_pic_status",
    "session_user_school_parent_id",
    "session_settings",
    "session_user_school_email",
    "session_language_array",
    "session_school_login_type",
  ];

  const onLogoutClick = () => {
    keysToRemove.forEach((key) => {
      localStorage.removeItem(key);
    });
  };
  return (
    <aside className={`left_sidebar ${toogle && "visible"}`} id="sidebar">
      <div className="scroll-sidebar">
        <nav className="sidebar_nav">
          <ul id="sidebarnav">
            <li
              className={`sidebar_nav_item ${
                option === "dashboard" && "active"
              }`}
            >
              <Link
                className="sidebar_nav_link"
                onClick={() => {
                  setToogle(false);
                }}
                to="/Home/dashboard"
              >
                <span className="sidebar_nav_link_icon">
                  <img className="img-fluid" src={home} alt="sidebar_icon" />
                </span>
                HOME
              </Link>
            </li>
            <li
              className={`sidebar_nav_item ${option === "profile" && "active"}`}
            >
              <Link
                className="sidebar_nav_link"
                onClick={() => {
                  setToogle(false);
                }}
                to="/Home/profile"
              >
                <span className="sidebar_nav_link_icon">
                  <img className="img-fluid" src={profile} alt="sidebar_icon" />
                </span>
                Profile
              </Link>
            </li>
            <li
              className={`sidebar_nav_item ${option === "course" && "active"}`}
            >
              <Link
                className="sidebar_nav_link"
                onClick={() => {
                  setToogle(false);
                }}
                to="/Home/course"
              >
                <span className="sidebar_nav_link_icon">
                  <img
                    className="img-fluid"
                    src={course_management}
                    alt="sidebar_icon"
                  />
                </span>
                COURSE
              </Link>
            </li>
            <li
              className={`sidebar_nav_item ${option === "message" && "active"}`}
            >
              <Link
                className="sidebar_nav_link"
                onClick={() => {
                  setToogle(false);
                }}
                to="/Home/message"
              >
                <span className="sidebar_nav_link_icon">
                  <img className="img-fluid" src={message} alt="sidebar_icon" />
                </span>
                MESSAGES
              </Link>
            </li>
            <li
              className={`sidebar_nav_item ${option === "events" && "active"}`}
            >
              <Link
                className="sidebar_nav_link"
                to="https://salsapeople.ch/workshop/"
              >
                <span className="sidebar_nav_link_icon">
                  <img className="img-fluid" src={event} alt="sidebar_icon" />
                </span>
                EVENTS
              </Link>
            </li>
            <li
              className={`sidebar_nav_item ${option === "media" && "active"}`}
            >
              <Link
                className="sidebar_nav_link"
                onClick={() => {
                  setToogle(false);
                }}
                to="/Home/media"
              >
                <span className="sidebar_nav_link_icon">
                  <img
                    className="img-fluid fill-white"
                    src={media}
                    alt="sidebar_icon"
                  />
                </span>
                MEDIA
              </Link>
            </li>
            <li
              className={`sidebar_nav_item ${option === "beHappy" && "active"}`}
            >
              <Link
                className="sidebar_nav_link"
                onClick={() => {
                  setToogle(false);
                }}
                to="/Home/beHappy"
              >
                <span className="sidebar_nav_link_icon">
                  <img
                    className="img-fluid"
                    src={be_happy}
                    alt="sidebar_icon"
                  />
                </span>
                BE HAPPY
              </Link>
            </li>
            <li className={`sidebar_nav_item ${option === "faq" && "active"}`}>
              <Link
                className="sidebar_nav_link"
                onClick={() => {
                  setToogle(false);
                }}
                to="/Home/faq"
              >
                <span className="sidebar_nav_link_icon">
                  <img className="img-fluid" src={faq} alt="sidebar_icon" />
                </span>
                FAQ
              </Link>
            </li>
            <li
              className={`sidebar_nav_item ${
                option1 === "language" && "active"
              }`}
            >
              <Link
                className="sidebar_nav_link"
                onClick={() => {
                  setToogle(false);
                }}
                to="/Home/language"
              >
                <span className="sidebar_nav_link_icon">
                  <img
                    className="img-fluid"
                    src={language}
                    alt="sidebar_icon"
                  />
                </span>
                LANGUAGE
              </Link>
            </li>
          </ul>
          <div className="logout_btn">
            <Link
              className="logout_link"
              onClick={() => onLogoutClick()}
              to="/"
            >
              <span className="logout_icon">
                <img className="img-fluid" src={logout} alt="sidebar_icon" />
              </span>
              LOG OUT
            </Link>
          </div>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
