import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dashboardProfileIcon from "../../assets/images/dashboard/Group 2442.png";
import profile from "../../assets/images/icons/profile.svg";
import course_management from "../../assets/images/icons/course_management.svg";
import message from "../../assets/images/icons/message.svg";
import event from "../../assets/images/icons/event.svg";
import media from "../../assets/images/icons/media.svg";
import axios from "axios";
import "./Dashboard.scss";

const Dashboard = () => {
  const [name, setName] = useState("");
  const [dashboardData, setDashboardData] = useState([]);
  const getDashboardData = async () => {
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("session_user_id"));
    formData.append("user_type", localStorage.getItem("session_user_type"));
    formData.append("status", "active");
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getPromotionalEvent&status=1`;
    await axios.post(api, formData).then((resp) => {
      setDashboardData(resp.data);
    });
  };

  const getName = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const login_action_user_type = localStorage.getItem(
      "session_school_login_type"
    );
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getlogininfo&user_id=${login_user_id}&action_type=${login_action_user_type}&status=1`;

    await axios.post(api).then((resp) => {
      setName(resp.data);
    });
  };

  useEffect(() => {
    getName();
    getDashboardData();
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="dashboard">
      <div className="container-fluid">
        <div className="dashboard_inner">
          <div className="showcase_card_booking">
            <div className="rank_card_column">
              <div className="rank_card">
                <div className="rank_card_inner">
                  <div className="ranker_img">
                    <img
                      className="img img-fluid"
                      src={dashboardProfileIcon}
                      alt="img"
                    />
                  </div>
                  <div className="ranker_name">
                    <h2>
                      {name?.user_firstname?.toUpperCase() || " "}{" "}
                      {name?.user_lastname?.toUpperCase() || " "}
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            {dashboardData?.event !== undefined && (
              <Link
                to="https://salsapeople.ch/workshop/"
                className="occasion_card_column"
              >
                <div className="occasion_card">
                  <div className="occasion_card_inner">
                    <div className="occasion_img">
                      <img
                        className="img-fluid"
                        src={
                          dashboardData?.event !== undefined &&
                          `https://www.salsaworldstandardclass.com/backend/upload_files/Event/${dashboardData?.event_id}/${dashboardData?.event[0]?.event_image}`
                        }
                        alt="img"
                      />
                    </div>
                    <div className="occasion_date_title">
                      <div className="occasion_title">
                        <h2>
                          {" "}
                          <span>
                            {" "}
                            {dashboardData?.event !== undefined &&
                              dashboardData?.event[0]?.event_title
                                .split(" ")
                                .slice(1)
                                .join(" ")}
                          </span>{" "}
                        </h2>
                      </div>
                      <div className="occasion_date">
                        <p>
                          {" "}
                          {dashboardData?.event !== undefined &&
                            dashboardData?.event[0]?.event_title.split(" ")[0]}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            )}
            <div className="booking_btn_column">
              <div className="booking_btn">
                <div className="course_btn">
                  <Link
                    className="btn btn_secondary"
                    to="/Home/course/bookCourse"
                  >
                    {localStorage.getItem("session_language") === "en"
                      ? "BOOK ONE-TIME COURSES"
                      : "Zusatzkurse buchen"}
                  </Link>
                </div>
                <div className="absence_btn">
                  <Link
                    className="btn btn_secondary"
                    to="/Home/course/placeAbsence"
                  >
                    {localStorage.getItem("session_language") === "en"
                      ? "PLACE ABSENCE"
                      : "Absenz erfassen"}
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="menu_card_group">
            <div className="row">
              <div className="menu_card">
                <Link className="menu_card_inner" to="/Home/profile">
                  <div className="menu_card_icon">
                    <img className="img-fluid" src={profile} alt="img" />
                  </div>
                  <h2>PROFILE</h2>
                </Link>
              </div>
              <div className="menu_card">
                <Link className="menu_card_inner" to="/Home/course">
                  <div className="menu_card_icon">
                    <img
                      className="img-fluid"
                      src={course_management}
                      alt="img"
                    />
                  </div>
                  <h2>COURSE MANAGEMENT</h2>
                </Link>
              </div>
              <div className="menu_card">
                <Link className="menu_card_inner" to="/Home/message">
                  <div className="menu_card_icon">
                    <img className="img-fluid" src={message} alt="img" />
                  </div>
                  <h2>MESSAGES</h2>
                </Link>
              </div>
              <div className="menu_card">
                <Link
                  className="menu_card_inner"
                  to="https://salsapeople.ch/workshop/"
                >
                  <div className="menu_card_icon">
                    <img className="img-fluid" src={event} alt="img" />
                  </div>
                  <h2>EVENTS</h2>
                </Link>
              </div>
              <div className="menu_card">
                <Link className="menu_card_inner" to="/Home/media">
                  <div className="menu_card_icon">
                    <img className="img-fluid" src={media} alt="img" />
                  </div>
                  <h2>MEDIA</h2>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
