import React, { useEffect, useState } from "react";
import img_usr from "../../../assets/images/personal_info/img_usr.png";
import "./MyPackage.scss";
import { Link } from "react-router-dom";
import axios from "axios";

const MyPackage = () => {
  const [packages, setPackages] = useState([]);
  const [fixPackages, setFixPackages] = useState([]);
  const [expirePackages, setExpirePackages] = useState([]);

  const getPackages = async () => {
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("session_user_id"));
    formData.append("user_type", localStorage.getItem("session_user_type"));

    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getPackages&status=1`;

    await axios.post(api, formData).then((resp) => {
      if (resp.data.status === false) {
        setPackages([]);
      } else {
        setPackages(resp.data);
      }
    });
  };

  const getFixPackages = async () => {
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("session_user_id"));
    formData.append("user_type", localStorage.getItem("session_user_type"));
    formData.append("school_id", localStorage.getItem("session_school_id"));

    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getPackagesWithFixCourse&status=1`;

    await axios.post(api, formData).then((resp) => {
      if (resp.data.status === false) {
        setFixPackages([]);
      } else {
        setFixPackages(resp.data);
      }
    });
  };

  const getExpirePackages = async () => {
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("session_user_id"));
    formData.append("user_type", localStorage.getItem("session_user_type"));

    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getPackagesExpire&status=1`;

    await axios.post(api, formData).then((resp) => {
      if (resp.data.status === false) {
        setExpirePackages([]);
      } else {
        setExpirePackages(resp.data);
      }
    });
  };

  const [data, setData] = useState("");

  const getdata = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const login_action_user_type = localStorage.getItem(
      "session_school_login_type"
    );
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getlogininfo&user_id=${login_user_id}&action_type=${login_action_user_type}&status=1`;

    await axios.post(api).then((resp) => {
      setData(resp.data);
    });
  };

  useEffect(() => {
    getPackages();
    getExpirePackages();
    getFixPackages();
    getdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="my_package_section">
      <div className="container-fluid">
        <div className="user_detail">
          <div className="user_pic_name">
            <div className="user_pic">
              <img className="img-fluid" src={img_usr} alt="user" />
            </div>
            <div className="user_name">
              <p>
                <span className="first_name">
                  {" "}
                  {data?.user_firstname || " "}
                </span>{" "}
                <span className="last_name">{data?.user_lastname || " "}</span>
              </p>
            </div>
          </div>
          <div className="my_package_title">
            <h2>MY PACKAGE</h2>
          </div>
        </div>
        <div className="my_package">
          <div className="my_package_inner">
            <div className="my_package_card_status">
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <Link
                    to="#"
                    className="nav-link active"
                    id="pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-home"
                    type="button"
                    role="tab"
                    aria-controls="pills-home"
                    aria-selected="true"
                  >
                    ACTIVE
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    to="#"
                    className="nav-link"
                    id="pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="pills-profile"
                    aria-selected="false"
                  >
                    EXPIRED
                  </Link>
                </li>
                <li className="nav-item" role="presentation">
                  <Link
                    to="#"
                    className="nav-link"
                    id="pills-third-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-third"
                    type="button"
                    role="tab"
                    aria-controls="pills-third"
                    aria-selected="false"
                  >
                    FIX
                  </Link>
                </li>
              </ul>
            </div>

            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
              tabIndex="0"
            >
              <div className="my_package_cards row">
                {packages?.map((item, index) => (
                  <div
                    className="my_package_card col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                    key={index}
                  >
                    <div className="my_package_card_inner">
                      <div className="my_package_card_title">
                        <h2>{item.user_package_description} : PAYMENT (1)</h2>
                      </div>
                      <div className="my_package_detail">
                        <ul className="my_package_list">
                          <li className="my_package_item">
                            <span>PACKAGE DURATION : </span>
                            <span>{item.user_package_duration}</span>
                          </li>
                          <li className="my_package_item">
                            <span>EXPIRE ON : </span>
                            <span>{item.user_package_expired_date}</span>
                          </li>
                          <li className="my_package_item">
                            <span>DESCRIPTION : </span>
                            <span>{item.user_package_description}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
              tabIndex="0"
            >
              <div className="my_package_cards row">
                {expirePackages?.map((item, index) => (
                  <div
                    className="my_package_card col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                    key={index}
                  >
                    <div className="my_package_card_inner">
                      <div className="my_package_card_title">
                        <h2>{item.user_package_description}</h2>
                      </div>
                      <div className="my_package_detail">
                        <ul className="my_package_list">
                          <li className="my_package_item">
                            <span>PACKAGE DURATION : </span>
                            <span>{item.user_package_duration}</span>
                          </li>
                          <li className="my_package_item">
                            <span>EXPIRE ON : </span>
                            <span>{item.user_package_expired_date}</span>
                          </li>
                          <li className="my_package_item">
                            <span>DESCRIPTION : </span>
                            <span>{item.user_package_description}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div
              className="tab-pane fade"
              id="pills-third"
              role="tabpanel"
              aria-labelledby="pills-third-tab"
              tabIndex="0"
            >
              <div className="my_package_cards row">
                {fixPackages !== null &&
                  Object.keys(fixPackages)?.map((item, index) => (
                    <div
                      className="my_package_card col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                      key={index}
                    >
                      <div className="my_package_card_inner">
                        <div className="my_package_card_title">
                          <h2>
                            {fixPackages[item].fix_course_student_course_title}
                          </h2>
                        </div>
                        <div className="my_package_detail">
                          <ul className="my_package_list">
                            <li className="my_package_item">
                              <span>START TIME : </span>
                              <span>
                                {
                                  fixPackages[item]
                                    .fix_course_student_class_start_time
                                }
                              </span>
                            </li>
                            <li className="my_package_item">
                              <span>END TIME : </span>
                              <span>
                                {
                                  fixPackages[item]
                                    .fix_course_student_class_end_time
                                }
                              </span>
                            </li>
                            <li className="my_package_item">
                              <span>STUDENT TYPE : </span>
                              <span>
                                {
                                  fixPackages[item]
                                    .fix_course_student_student_type
                                }
                              </span>
                            </li>
                            <li className="my_package_item">
                              <span>DESCRIPTION : </span>
                              <span>
                                {
                                  fixPackages[item]
                                    .fix_course_student_course_description
                                }
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyPackage;
