import axios from "axios";
import "./Faq.scss";
import React, { useEffect, useState } from "react";

const Faq = () => {
  const [faqdata, setFaqData] = useState([]);
  const [btnData, setBtnData] = useState([]);
  const [divData, setDivData] = useState([]);

  const allBtnvalues = () => {
    let buttons = document.querySelectorAll("button");
    let btnArray = [];
    buttons.forEach(
      (element, indexBtn) =>
        element.className === "accordion" && btnArray.push(element.innerHTML)
    );
    setBtnData(btnArray);
  };

  const allDivvalues = () => {
    let innerValueOfButton = document.querySelectorAll("div");
    let divArray = [];
    innerValueOfButton.forEach(
      (element, indexDiv) =>
        element.className === "panel" && divArray.push(element.innerHTML)
    );
    setDivData(divArray);
  };

  const getFaqData = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");

    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("session_user_id"));

    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getFaq&status=1`;
    await axios.post(api, formData).then((resp) => {
      setFaqData(resp.data.html);
      allBtnvalues();
      allDivvalues();
    });
  };

  useEffect(() => {
    getFaqData();
  }, [faqdata]);
  return (
    <>
      <div
        dangerouslySetInnerHTML={{ __html: faqdata }}
        className="hideFaqData"
      ></div>
      <div className="faq_section">
        <div className="container-fluid">
          <div className="user_detail">
            <div className="faq_title">
              <h2>FAQ</h2>
            </div>
          </div>
          <div className="accordion" id="accordionExample">
            {btnData?.map((item, index) => (
              <div className="accordion-item" key={index}>
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapseOne-${index}`}
                    aria-expanded="false"
                    aria-controls={`collapseOne-${index}`}
                  >
                    {item}
                  </button>
                </h2>
                <div
                  id={`collapseOne-${index}`}
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div className="faq_detail">
                      <p
                        dangerouslySetInnerHTML={{ __html: divData[index] }}
                      ></p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
