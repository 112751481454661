import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./EnrollCouple.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const EnrollCouple = () => {
  const [name, setName] = useState("");
  const [showEnroll, setShowEnroll] = useState(false);
  const [data, setData] = useState([]);
  const { option2 } = useParams();
  const navigate = useNavigate();

  const searchStudent = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");

    await axios
      .post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getFilteredStudent&student_name=${name}&status=1`
      )
      .then((resp) => {
        if (resp.data.status === true) {
          setData(resp.data.student);
          setShowEnroll(true);
          toast(
            "Student found successfully! Please submit to enroll as couple"
          );
        }
        if (resp.data.status === false) {
          toast("Student not found, Please search again!");
        }
      });
  };

  const enrollStudent = async () => {
    const params = new URLSearchParams(option2);
    const course_id = params.get("course_id");
    const course_title = params.get("course_title");

    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");

    await axios
      .post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=enrollCouplenew&school_id=${localStorage.getItem(
          "session_school_id"
        )}&level_id=${localStorage.getItem(
          "session_user_level_id"
        )}&course_id=${course_id}&user_id=${localStorage.getItem(
          "session_user_id"
        )}&course_title=${course_title}&partner_id=${data[0].student_id}&status=1`
      )
      .then((resp) => {
        if (resp.data.status === true) {
          toast("ENROLLED SUCCESSFULLY");
          setTimeout(() => {
            navigate(`/Home/course/applyCourse/${course_id}`);
          }, 3000);
        }
      });
  };

  return (
    <div className="apply_course_search_section">
      <ToastContainer />
      <div className="container-fluid">
        <div className="user_detail">
          <div className="apply_course_title">
            <h2>Apply Course</h2>
          </div>
        </div>
        <div className="apply_course_search">
          <form className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <input
                type="text"
                className="form-control"
                id="inputFirstname"
                placeholder="Search Student Name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="col-12">
              {showEnroll ? (
                <Link
                  className="btn btn_primary"
                  href="#"
                  onClick={() => enrollStudent()}
                >
                  Enroll
                </Link>
              ) : (
                <Link
                  className="btn btn_primary"
                  href="#"
                  onClick={() => searchStudent()}
                >
                  Search
                </Link>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EnrollCouple;
