import React from "react";
import { Link } from "react-router-dom";
import message_notification from "../../../assets/images/icons/message_notification.svg";
import chat from "../../../assets/images/icons/chat.svg";
import "./MessageBlocks.scss";

const MessageBlocks = () => {
  return (
    <div className="message_blocks">
      <div className="container-fluid">
        <div className="message_blocks_inner">
          <div className="message_blocks_group">
            <div className="row">
              <div className="message_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                <Link
                  className="message_block_inner"
                  to="/Home/message/messageAndNotification"
                >
                  <div className="message_block_icon">
                    <img
                      className="img-fluid"
                      src={message_notification}
                      alt="message_notification"
                    />
                  </div>
                  <h2>Message & Notification</h2>
                </Link>
              </div>
              <div className="message_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                <Link
                  className="message_block_inner"
                  to="/Home/message/chatLsit"
                >
                  <div className="message_block_icon">
                    <img className="img-fluid" src={chat} alt="chatLsit" />
                  </div>
                  <h2>Chat</h2>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageBlocks;
