import React, { useEffect, useState } from "react";
import "./ChatList.scss";
import { Link } from "react-router-dom";
import axios from "axios";

const ChatList = () => {
  const [data, setData] = useState(null);
  const [chatTitile, setChatTitile] = useState(null);

  const checklistCeoDetails = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const user_id = localStorage.getItem("session_user_id");

    const findAllGroup = () => {
      const allChatLink = document.querySelectorAll(".chat_list_hide li a");
      const allTitle = [];
      allChatLink.forEach((element) => {
        allTitle.push({
          title: element.innerHTML,
          groupId: element
            .getAttribute("onclick")
            .match(/functions\.chat\.chatList\((\d+)\)/)[1],
        });
      });
      setChatTitile(allTitle);
    };

    await axios
      .post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getGroupChat&user_id=${user_id}&status=1`
      )
      .then((resp) => {
        setData(resp.data);
        findAllGroup();
      });
  };
  useEffect(() => {
    checklistCeoDetails();
  }, [data]);
  return (
    <div className="chat_list_section">
      <div
        className="chat_list_hide"
        dangerouslySetInnerHTML={{ __html: data }}
      ></div>
      <div className="container-fluid">
        <div className="user_detail">
          <div className="courses_title">
            <h2>Chat List</h2>
          </div>
        </div>
        <div className="chat_list">
          <div className="chat_list_inner">
            <div className="chat_list_blocks">
              <ul>
                {chatTitile?.map((item, index) => (
                  <li className="chat_list_block" key={index}>
                    <Link
                      to={`/Home/message/chat/${item.groupId}`}
                      className="chat_list_link"
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatList;
