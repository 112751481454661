import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Protokoll.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const Protokoll = () => {
  const [kursList, setKursList] = useState([]);
  const [kursToogle, setKursToogle] = useState(false);
  const [kursToogleValue, setKursToogleValue] = useState(null);

  const [selectDate, setSelectDate] = useState("");
  const [problemNote, setProblemNote] = useState("");
  const [salaryNote, setSalaryNote] = useState("");
  const [radio, setRadio] = useState(null);

  const getProtokollDetails = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const formData = new FormData();
    formData.append(
      "form_data",
      `user_id=${localStorage.getItem(
        "session_user_id"
      )}&course=${kursToogleValue}&protocol_date=${selectDate}&problem_note=${problemNote}&salary_note=${salaryNote}&radio=${radio}`
    );
    if (
      kursToogleValue === null ||
      selectDate === "" ||
      problemNote === "" ||
      salaryNote === "" ||
      radio === null
    ) {
      toast("All Fields Are Mandatory");
    } else {
      await axios
        .post(
          `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=addProtokoll&status=1`,
          formData
        )
        .then((resp) => {
          toast("Protokoll Added Successfully");
        });
    }
  };

  const getKurslist = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const formData = new FormData();
    formData.append("school_id", localStorage.getItem("session_school_id"));
    await axios
      .post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getSalaryPage&status=1`,
        formData
      )
      .then((resp) => {
        setKursList(resp.data.c1);
      });
  };
  useEffect(() => {
    getKurslist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="protokoll_section">
      <ToastContainer />
      <div className="container-fluid">
        <div className="user_detail">
          <div className="protokoll_title">
            <Link className="" to="#">
              Protokoll
            </Link>
          </div>
        </div>
        <div className="protokoll_card">
          <div className="protokoll_card_inner">
            <div className="protokoll_form">
              <form className="row">
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                  <label for="inputDate" className="form-label">
                    Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="inputDate"
                    value={selectDate}
                    onChange={(e) => {
                      setSelectDate(e.target.value);
                    }}
                  />
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                  <label for="inputKurs" className="form-label">
                    Kurs
                  </label>
                  <div className="type-select">
                    <button
                      className={`btn dropdown-toggle4 ${kursToogle && "show"}`}
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => {
                        setKursToogle(!kursToogle);
                      }}
                    >
                      {kursToogleValue === null ? "Select" : kursToogleValue}
                    </button>
                    <ul className={`dropdown-menu ${kursToogle && "show"}`}>
                      {kursList?.map((item, index) => (
                        <li key={index}>
                          <Link
                            key={index}
                            className="dropdown-item"
                            href="#"
                            onClick={() => {
                              setKursToogle(false);
                              setKursToogleValue(item.course_title);
                            }}
                          >
                            {item.course_title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <label for="inputNote" className="form-label">
                    Problems *
                  </label>
                  <textarea
                    className="form-control"
                    id="inputNote"
                    rows="4"
                    value={problemNote}
                    onChange={(e) => {
                      setProblemNote(e.target.value);
                    }}
                  />
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <label for="inputNote" className="form-label">
                    Notes *
                  </label>
                  <textarea
                    className="form-control"
                    id="inputNote"
                    rows="4"
                    value={salaryNote}
                    onChange={(e) => {
                      setSalaryNote(e.target.value);
                    }}
                  />
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <label for="inputNote" className="form-label">
                    Mood barometer * :
                  </label>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      value={radio}
                      onChange={(e) => {
                        setRadio(1);
                      }}
                    />
                    <label className="form-check-label" for="inlineRadio1">
                      1
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value={radio}
                      onChange={(e) => {
                        setRadio(2);
                      }}
                    />
                    <label className="form-check-label" for="inlineRadio2">
                      2
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value={radio}
                      onChange={(e) => {
                        setRadio(3);
                      }}
                    />
                    <label className="form-check-label" for="inlineRadio2">
                      3
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value={radio}
                      onChange={(e) => {
                        setRadio(4);
                      }}
                    />
                    <label className="form-check-label" for="inlineRadio2">
                      4
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value={radio}
                      onChange={(e) => {
                        setRadio(5);
                      }}
                    />
                    <label className="form-check-label" for="inlineRadio2">
                      5
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value={radio}
                      onChange={(e) => {
                        setRadio(6);
                      }}
                    />
                    <label className="form-check-label" for="inlineRadio2">
                      6
                    </label>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <Link
                    className="btn btn_primary"
                    onClick={getProtokollDetails}
                  >
                    Submit
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Protokoll;
