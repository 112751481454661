import React, { useEffect, useState } from "react";
import axios from "axios";
import "./DepartmentTask.scss";

const DepartmentTask = () => {
  const [data, setData] = useState(null);
  const departmentTaskDetails = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const user_id = localStorage.getItem("session_user_id");
    const formData = new FormData();
    formData.append("user_id", user_id);
    await axios
      .post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getdepartmentTask&status=1`,
        formData
      )
      .then((resp) => {
        setData(resp.data);
      });
  };
  useEffect(() => {
    departmentTaskDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div className="department_section">
      <div className="container-fluid">
        <div className="user_detail">
          <div className="department_title">
            <h2>Department TASKS</h2>
          </div>
        </div>
        <div className="department_inner">
          <div className="department_table">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Task Name</th>
                    <th>Responsible Teacher</th>
                    <th>Repeat</th>
                    <th>Notes</th>
                    <th>Status</th>
                    <th>Deadline Date</th>
                    <th>Warning Date</th>
                    <th>Telephone Detail</th>
                    <th>Check accept/decline</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody
                  className="table-group-divider"
                  dangerouslySetInnerHTML={{ __html: data }}
                ></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepartmentTask;
