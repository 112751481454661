import React, { useState, useEffect } from "react";
import "./OpenBill.scss";
import axios from "axios";

const OpenBill = () => {
  const [billData, setBillData] = useState([]);

  const getFaqData = async () => {
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("session_user_id"));
    formData.append("user_type", localStorage.getItem("session_user_type"));

    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=showopenbill&status=1`;
    await axios.post(api, formData).then((resp) => {
      setBillData(resp.data);
    });
  };

  useEffect(() => {
    getFaqData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billData]);
  return (
    <div className="openbill_section">
      <div className="container-fluid">
        <div className="user_detail">
          <div className="notifications_title">
            <h2>openbill</h2>
          </div>
        </div>
        <div className="openbill_inner">
          <div className="openbill_table">
            <div className="row">
              <div className="col-12">
                <div className="table-responsive-xxl">
                  {billData !==
                  "<tr><td colspan='7'>No Records Available</td></tr>" ? (
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Category</th>
                          <th scope="col">Text</th>
                          <th scope="col">Ticket Received</th>
                          <th scope="col">Price</th>
                          <th scope="col">Status</th>
                          <th scope="col">Created Date</th>
                        </tr>
                      </thead>
                      <tbody
                        className="table-group-divider"
                        dangerouslySetInnerHTML={{
                          __html: billData,
                        }}
                      ></tbody>
                    </table>
                  ) : (
                    <p>No Data Available.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenBill;
