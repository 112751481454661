import React, { useEffect, useState } from "react";
import "./OverviewCourse.scss";
import { Link } from "react-router-dom";
import axios from "axios";

const OverviewCourse = () => {
  const [bookCourse, setBookCourse] = useState([]);
  const [data, setData] = useState([]);

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const conditionalDays = ["so", "mo", "di", "mi", "do", "fr", "sa"];
  const bookCourses = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("session_user_id"));
    formData.append("user_type", localStorage.getItem("session_user_type"));
    formData.append("school_id", localStorage.getItem("session_school_id"));
    await axios
      .post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getCourseBySchoolAndLevelIdDev&status=1`,
        formData
      )
      .then((resp) => {
        setBookCourse(resp.data["All courses"]);
        setData(resp.data);
      });
  };
  useEffect(() => {
    bookCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="courses_section">
      <div className="container-fluid">
        <div className="my_courses">
          <div className="user_detail">
            <div className="courses_title">
              <h2>OverView Courses</h2>
            </div>
          </div>

          <div className="courses">
            <div className="courses_inner">
              <div className="courses_by_day">
                <div className="accordion" id="accordionExampleThree">
                  {Object.values(data)?.map((bookInnerItem, bookInnerIndex) => (
                    <div
                      className="accordion-item"
                      key={`${bookInnerIndex}-ThreeTabs`}
                    >
                      <h2
                        className="accordion-header"
                        id={`heading${bookInnerIndex}-ThreeTabs`}
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse${bookInnerIndex}-ThreeTabs`}
                          aria-expanded="false"
                          aria-controls={`collapse${bookInnerIndex}-ThreeTabs`}
                        >
                          {Object.keys(data)[bookInnerIndex] === "Normal"
                            ? localStorage.getItem("session_language") === "en"
                              ? Object.keys(data)[bookInnerIndex]
                              : "Hauptkurs"
                            : Object.keys(data)[bookInnerIndex] === "Fixed"
                            ? localStorage.getItem("session_language") === "en"
                              ? Object.keys(data)[bookInnerIndex]
                              : "Fixer Zusatzkurs"
                            : Object.keys(data)[bookInnerIndex] === "One Time"
                            ? localStorage.getItem("session_language") === "en"
                              ? Object.keys(data)[bookInnerIndex]
                              : "Einmal"
                            : Object.keys(data)[bookInnerIndex]}
                        </button>
                      </h2>
                      <div
                        id={`collapse${bookInnerIndex}-ThreeTabs`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`heading${bookInnerIndex}-ThreeTabs`}
                        data-bs-parent="#accordionExampleThree"
                      >
                        <div className="accordion-body">
                          {Object.keys(data)[bookInnerIndex] ===
                          "All courses" ? (
                            <div className="courses">
                              <div className="courses_inner">
                                <div className="courses_card_status">
                                  <ul
                                    className="nav nav-pills mb-3"
                                    id="pills-tab"
                                    role="tablist"
                                  >
                                    {Object.values(bookCourse)?.map(
                                      (bookItem, bookIndex) =>
                                        conditionalDays.includes(
                                          Object.keys(bookCourse)[bookIndex]
                                        ) && (
                                          <li
                                            className="nav-item"
                                            role="presentation"
                                            key={bookIndex}
                                          >
                                            <Link
                                              href="#"
                                              className={`nav-link ${
                                                bookIndex === 0 && "active"
                                              }`}
                                              id={`pills-${bookIndex}-tab`}
                                              data-bs-toggle="pill"
                                              data-bs-target={`#pills-${bookIndex}`}
                                              type="button"
                                              role="tab"
                                              aria-controls={`pills-${bookIndex}`}
                                              aria-selected={
                                                bookIndex === 0
                                                  ? "true"
                                                  : "false"
                                              }
                                            >
                                              {
                                                days[
                                                  conditionalDays.indexOf(
                                                    Object.keys(bookCourse)[
                                                      bookIndex
                                                    ]
                                                  )
                                                ]
                                              }
                                            </Link>
                                          </li>
                                        )
                                    )}
                                  </ul>
                                </div>

                                {Object.values(bookCourse)?.map(
                                  (bookItem, bookIndex) => (
                                    <div
                                      className={`tab-pane fade ${
                                        bookIndex === 0 && "show active"
                                      }`}
                                      id={`pills-${bookIndex}`}
                                      role="tabpanel"
                                      aria-labelledby={`pills-${bookIndex}-tab`}
                                      tabIndex="0"
                                      key={`${bookIndex}-tab`}
                                    >
                                      <div className="courses_by_day">
                                        <div
                                          className="accordion"
                                          id="accordionExample"
                                        >
                                          {Object.values(bookItem)?.map(
                                            (bookInnerItem, bookInnerIndex) => (
                                              <div
                                                className="accordion-item"
                                                key={`${bookIndex}-${bookInnerIndex}`}
                                              >
                                                <h2
                                                  className="accordion-header"
                                                  id={`heading${bookInnerIndex}-${bookIndex}`}
                                                >
                                                  <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#collapse${bookInnerIndex}-${bookIndex}`}
                                                    aria-expanded="false"
                                                    aria-controls={`collapse${bookInnerIndex}-${bookIndex}`}
                                                  >
                                                    {
                                                      Object.keys(bookItem)[
                                                        bookInnerIndex
                                                      ]
                                                    }
                                                  </button>
                                                </h2>
                                                <div
                                                  id={`collapse${bookInnerIndex}-${bookIndex}`}
                                                  className="accordion-collapse collapse"
                                                  aria-labelledby={`heading${bookInnerIndex}-${bookIndex}`}
                                                  data-bs-parent="#accordionExample"
                                                >
                                                  <div className="accordion-body">
                                                    <div className="courses">
                                                      <div className="courses_inner">
                                                        <div className="courses_card_status">
                                                          <ul
                                                            className="nav nav-pills mb-3"
                                                            id="pills-tab"
                                                            role="tablist"
                                                          >
                                                            {Object.values(
                                                              bookInnerItem
                                                            )?.map(
                                                              (
                                                                bookDayItem,
                                                                bookDayIndex
                                                              ) => (
                                                                <li
                                                                  className="nav-item"
                                                                  role="presentation"
                                                                  key={`${bookIndex}-${bookInnerIndex}-${bookDayIndex}`}
                                                                >
                                                                  <Link
                                                                    href="#"
                                                                    className={`nav-link ${
                                                                      bookDayIndex ===
                                                                        0 &&
                                                                      "active"
                                                                    }`}
                                                                    id={`pills-${bookInnerIndex}-${bookIndex}-salsa-${bookDayIndex}-tab`}
                                                                    data-bs-toggle="pill"
                                                                    data-bs-target={`#pills-${bookInnerIndex}-${bookIndex}-salsa-${bookDayIndex}`}
                                                                    type="button"
                                                                    role="tab"
                                                                    aria-controls={`pills-${bookInnerIndex}-${bookIndex}-salsa-${bookDayIndex}`}
                                                                    aria-selected={
                                                                      bookIndex ===
                                                                      0
                                                                        ? "true"
                                                                        : "false"
                                                                    }
                                                                  >
                                                                    {
                                                                      Object.keys(
                                                                        bookInnerItem
                                                                      )[
                                                                        bookDayIndex
                                                                      ]
                                                                    }
                                                                  </Link>
                                                                </li>
                                                              )
                                                            )}
                                                          </ul>
                                                        </div>
                                                        {Object.values(
                                                          bookInnerItem
                                                        )?.map(
                                                          (
                                                            bookDayItem,
                                                            bookDayIndex
                                                          ) => (
                                                            <div
                                                              className={`tab-pane fade ${
                                                                bookDayIndex ===
                                                                  0 &&
                                                                "show active"
                                                              }`}
                                                              id={`pills-${bookInnerIndex}-${bookIndex}-salsa-${bookDayIndex}`}
                                                              role="tabpanel"
                                                              aria-labelledby={`pills-${bookInnerIndex}-${bookIndex}-salsa-${bookDayIndex}-tab`}
                                                              tabIndex="0"
                                                              key={`${bookIndex}-${bookInnerIndex}-${bookDayIndex}-tab`}
                                                            >
                                                              <div className="courses_cards row">
                                                                {Object.values(
                                                                  bookDayItem
                                                                )?.map(
                                                                  (
                                                                    bookLevelItem,
                                                                    bookLevelIndex
                                                                  ) =>
                                                                    Object.values(
                                                                      bookLevelItem
                                                                    )?.map(
                                                                      (
                                                                        bookLevelValueItem,
                                                                        bookLevelValueIndex
                                                                      ) => (
                                                                        <Link
                                                                          to={`/Home/profile/courses/${bookLevelValueItem.course_id}`}
                                                                          className="courses_card col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                                                          key={`${bookIndex}-${bookInnerIndex}-${bookDayIndex}-${bookLevelIndex}-${bookLevelValueIndex}`}
                                                                        >
                                                                          <div className="courses_card_inner">
                                                                            <div className="courses_card_title">
                                                                              <h2>
                                                                                {" "}
                                                                                {
                                                                                  Object.keys(
                                                                                    bookDayItem
                                                                                  )[
                                                                                    bookLevelIndex
                                                                                  ]
                                                                                }
                                                                              </h2>
                                                                            </div>
                                                                            <div className="courses_detail">
                                                                              <ul className="courses_list">
                                                                                <li className="courses_item">
                                                                                  <span>
                                                                                    {
                                                                                      Object.keys(
                                                                                        bookInnerItem
                                                                                      )[
                                                                                        bookDayIndex
                                                                                      ]
                                                                                    }{" "}
                                                                                    Level
                                                                                    :
                                                                                  </span>
                                                                                  <span>
                                                                                    {
                                                                                      bookLevelValueItem?.Level_id
                                                                                    }
                                                                                  </span>
                                                                                </li>
                                                                                <li className="courses_item">
                                                                                  <span>
                                                                                    Starting
                                                                                    Time
                                                                                    :
                                                                                  </span>
                                                                                  <span>
                                                                                    {
                                                                                      bookLevelValueItem?.class_start_time
                                                                                    }
                                                                                  </span>
                                                                                </li>
                                                                                <li className="courses_item">
                                                                                  <span>
                                                                                    Ending
                                                                                    Time
                                                                                    :
                                                                                  </span>
                                                                                  <span>
                                                                                    {
                                                                                      bookLevelValueItem?.class_end_time
                                                                                    }
                                                                                  </span>
                                                                                </li>
                                                                                <li className="courses_item">
                                                                                  <span>
                                                                                    Room
                                                                                    :
                                                                                  </span>
                                                                                  <span>
                                                                                    {
                                                                                      bookLevelValueItem?.course_room
                                                                                    }
                                                                                  </span>
                                                                                </li>
                                                                                <li className="courses_item">
                                                                                  <span>
                                                                                    Teacher
                                                                                    1
                                                                                    :
                                                                                  </span>
                                                                                  <span>
                                                                                    {
                                                                                      bookLevelValueItem?.teacher1
                                                                                    }
                                                                                  </span>
                                                                                </li>
                                                                                <li className="courses_item">
                                                                                  <span>
                                                                                    Teacher
                                                                                    2
                                                                                    :
                                                                                  </span>
                                                                                  <span>
                                                                                    {
                                                                                      bookLevelValueItem?.teacher2
                                                                                    }
                                                                                  </span>
                                                                                </li>
                                                                              </ul>
                                                                            </div>
                                                                          </div>
                                                                        </Link>
                                                                      )
                                                                    )
                                                                )}
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="courses">
                              <div className="courses_cards row">
                                {Object.values(bookInnerItem)?.map(
                                  (bookLevelItem, bookLevelIndex) => (
                                    <Link
                                      to={`/Home/profile/courses/${bookLevelItem.course_id}`}
                                      className="courses_card col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                      key={`${bookInnerIndex}-Card`}
                                    >
                                      <div className="courses_card_inner">
                                        <div className="courses_card_title">
                                          <h2>
                                            {" "}
                                            {bookLevelItem?.course_title}
                                          </h2>
                                        </div>
                                        <div className="courses_detail">
                                          <ul className="courses_list">
                                            <li className="courses_item">
                                              <span>Salsa Level :</span>
                                              <span>
                                                {bookLevelItem?.Level_id}
                                              </span>
                                            </li>
                                            <li className="courses_item">
                                              <span>Starting Time :</span>
                                              <span>
                                                {
                                                  bookLevelItem?.class_start_time
                                                }
                                              </span>
                                            </li>
                                            <li className="courses_item">
                                              <span>Ending Time :</span>
                                              <span>
                                                {bookLevelItem?.class_end_time}
                                              </span>
                                            </li>
                                            <li className="courses_item">
                                              <span>Room :</span>
                                              <span>
                                                {bookLevelItem?.course_room}
                                              </span>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </Link>
                                  )
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewCourse;
