import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoutes = () => {
  const keysToRemove = [
    "is_pause_student",
    "session_user_device_id",
    "session_user_level_id",
    "session_admin_name",
    "session_course_id_detail_page_forvideo",
    "session_user_updated_date",
    "session_user_country",
    "session_setting_show_pic_inside_group",
    "session_user_lastname",
    "message_absent",
    "session_language_array",
    "session_user_login_cookie",
    "session_user_package_description",
    "session_user_renewal_notify",
    "session_user_selected_type",
    "session_user_status",
    "session_admin_email",
    "session_setting_newsletter",
    "session_user_contact",
    "session_user_password",
    "session_user_payment_status",
    "session_user_address",
    "session_user_package_expired_date",
    "session_user_is_ceo",
    "session_user_school_email",
    "session_user_state",
    "session_setting_show_online_friends",
    "session_user_sender_id",
    "session_user_city",
    "session_user_id",
    "session_user_email",
    "session_user_profile_pic",
    "session_school_image",
    "session_student_teacher",
    "session_user_created_date",
    "session_premium_status_page",
    "session_setting_event",
    "session_admin_id",
    "session_setting_account",
    "session_user_package_cost",
    "session_user_gender",
    "session_user_school_name",
    "teacher_id_student",
    "session_user_type",
    "session_setting_notification",
    "session_language",
    "session_user_zipcode",
    "is_external_teacher",
    "review",
    "session_video_id_for_video_detail_page",
    "session_user_firstname",
    "session_school_id",
    "session_user_api_id",
    "session_user_package_duration",
    "session_school_type",
    "session_user_package_id",
    "session_user_package_activated_date",
    "session_user_profile_pic_status",
    "session_user_school_parent_id",
    "session_settings",
    "session_user_school_email",
    "session_language_array",
    "session_school_login_type",
  ];

  useEffect(() => {
    if (Number(localStorage.getItem("is_pause_student")) === 1) {
      keysToRemove.forEach((key) => {
        localStorage.removeItem(key);
      });
    }
  });
  const auth = {
    token:
      localStorage.getItem("session_user_status") === "Active" &&
      Number(localStorage.getItem("is_pause_student")) === 0
        ? true
        : false,
  };

  return auth.token ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
