import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Play from "../../../assets/images/icons/play-circle.svg";
import Close from "../../../assets/images/icons/close.svg";
import "./MusicPlaylist.scss";
import axios from "axios";

const MusicPlaylist = () => {
  const [data, setData] = useState(null);
  const [iframeData, setIframeData] = useState([]);
  const [titleData, setTitleData] = useState([]);
  const [currentIframeData, setCurrentIframeData] = useState(null);
  const [currentTitle, setCurrentTitle] = useState(null);

  const musicDetails = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");

    const getMusicSrc = () => {
      const iframes = document.querySelectorAll("iframe");
      let listOfIframes = [];
      iframes.forEach((element) => {
        listOfIframes.push(element.src);
      });

      setIframeData(listOfIframes);
    };

    const getMusicTitle = () => {
      const iframes = document.querySelectorAll("#courseGrp h3 a");
      let listOfTitle = [];
      iframes.forEach((element) => {
        listOfTitle.push(element.innerText);
      });

      setTitleData(listOfTitle);
    };

    await axios
      .post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=youtubelist&status=1`
      )
      .then((resp) => {
        setData(resp.data);
        getMusicSrc();
        getMusicTitle();
      });
  };

  useEffect(() => {
    musicDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div className="music_playlist_section">
      <div
        dangerouslySetInnerHTML={{ __html: data }}
        className="dataToHide"
      ></div>
      <div className="container-fluid">
        <div className="user_detail">
          <div className="music_playlist_title">
            <h2>MUSIC PLAYLIST</h2>
          </div>
        </div>
        <div className="video_list_modal">
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header d-flex align-items-center">
                  <h3>{currentTitle}</h3>
                  <Link
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <img className="img-fluid" src={Close} alt="" />
                  </Link>
                </div>
                <div className="modal-body">
                  <div className="date_time_title"></div>
                  <div className="video_block">
                    <iFrame
                      src={currentIframeData}
                      width="100%"
                      height="100%"
                      frameBorder="0"
                      allow="autoplay; fullscreen; picture-in-picture"
                      allowFullScreen
                    ></iFrame>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="music_playlist">
          <div className="music_playlist_inner">
            <div className="music_playlist_cards row">
              {titleData?.map((iframeDataSrc, index) => (
                <div
                  className="music_playlist_card col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                  key={index}
                >
                  <div className="music_playlist_card_inner">
                    <div className="music_playlist_card_row">
                      <div className="music_playlist_card_column">
                        <div className="music_playlist_card_title">
                          <h3>{titleData[index]}</h3>
                        </div>
                      </div>

                      <div className="music_playlist_card_column">
                        <div className="delete-icon">
                          <Link
                            href="#"
                            type="button"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          >
                            <img
                              className="img-fluid"
                              src={Play}
                              onClick={() => {
                                setCurrentIframeData(iframeData[index]);
                                setCurrentTitle(titleData[index]);
                              }}
                              alt=""
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MusicPlaylist;
