import React from "react";
import Message from "../Message/Message";
import Notification from "../Notification/Notification";
import { Link } from "react-router-dom";
import "./MessagesAndNotification.scss";
import { ToastContainer } from "react-toastify";
import CoupleRequest from "../CoupleRequest/CoupleRequest";

const MessagesAndNotification = () => {
  return (
    <div className="my_package">
      <ToastContainer />
      <div className="my_package_inner">
        <div className="my_package_card_status">
          <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li className="nav-item" role="presentation">
              <Link
                to="#"
                className="nav-link active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                MESSAGE
              </Link>
            </li>
            <li className="nav-item" role="presentation">
              <Link
                to="#"
                className="nav-link"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                NOTIFICATION
              </Link>
            </li>
            <li className="nav-item" role="presentation">
              <Link
                to="#"
                className="nav-link"
                id="pills-request-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-request"
                type="button"
                role="tab"
                aria-controls="pills-request"
                aria-selected="false"
              >
                REQUESTS
              </Link>
            </li>
          </ul>
        </div>

        <div
          className="tab-pane fade show active"
          id="pills-home"
          role="tabpanel"
          aria-labelledby="pills-home-tab"
          tabIndex="0"
        >
          <div className="my_package_cards row">
            <Message />
          </div>
        </div>

        <div
          className="tab-pane fade"
          id="pills-profile"
          role="tabpanel"
          aria-labelledby="pills-profile-tab"
          tabIndex="0"
        >
          <div className="my_package_cards row">
            <Notification />
          </div>
        </div>

        <div
          className="tab-pane fade"
          id="pills-request"
          role="tabpanel"
          aria-labelledby="pills-request-tab"
          tabIndex="0"
        >
          <div className="my_package_cards row">
            <CoupleRequest />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagesAndNotification;
