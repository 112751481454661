import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./ChecklistTeacherVorbereitung.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const ChecklistTeacherVorbereitung = () => {
  const [teacherChecklist, setTeacherChecklist] = useState([]);
  const [abschlussCards, setAbschlussCards] = useState([]);
  const [abschlussTitle, setAbschlussTitle] = useState([]);
  const [abschlussValue, setAbschlussValue] = useState([]);
  const [abschlussData, setAbschlussData] = useState([]);

  const checklistTeacher = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");

    const findPlinDiv = () => {
      const allPlink = document.querySelectorAll(".plink");
      const fullData = [];
      allPlink.forEach((element) => {
        let data = element.innerHTML.match(/^(.*?)<input/);
        if (data && data.length >= 2) {
          fullData.push(data[1].trim());
        }
      });

      const plinkData = document.querySelectorAll(".plink input");
      const mainDatas = [];
      plinkData.forEach((element) => {
        !mainDatas.includes(element.getAttribute("data-roomname")) &&
          mainDatas.push(element.getAttribute("data-roomname"));
      });
      setAbschlussCards(mainDatas);

      let current = 0;
      const titleArray = [];
      const valueArray = [];
      plinkData.forEach((element, index) => {
        if (element.getAttribute("data-roomname") === mainDatas[current]) {
          if (valueArray[current]) {
            titleArray[current].push(fullData[index]);
            valueArray[current].push(element.value);
          } else {
            titleArray.push([fullData[index]]);
            valueArray.push([element.value]);
          }
        } else {
          current += 1;
          if (valueArray[current + 1]) {
            titleArray[current + 1].push(fullData[index]);
            valueArray[current + 1].push(element.value);
          } else {
            titleArray.push([fullData[index]]);
            valueArray.push([element.value]);
          }
        }
      });
      setAbschlussTitle(titleArray);
      setAbschlussValue(valueArray);
    };

    await axios
      .post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getTeacherChecklistById&teacher_id=170&location=ZH&status=1`
      )
      .then((resp) => {
        setTeacherChecklist(resp.data);
        findPlinDiv();
      });
  };

  useEffect(() => {
    checklistTeacher();
  }, [teacherChecklist]);

  const submitData = (e) => {
    e.preventDefault();

    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const formData = new FormData();
    formData.append("point_type", "vorbereitung");
    formData.append("teacher_id", localStorage.getItem("teacher_id_student"));
    abschlussData.map((listID) => {
      formData.append("option_list[]", listID);
    });

    if (abschlussData.length < 1) {
      toast("Please Select Checklist");
    } else {
      axios
        .post(
          `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=addTeacherChecklistMultiple&status=1`,
          formData
        )
        .then((resp) => {
          if (resp.status) {
            toast("Checklist Added Successfully");
            setAbschlussData([]);
          }
        });
    }
  };
  return (
    <div className="vorbereitung_section">
      <div
        className="dataToHide"
        dangerouslySetInnerHTML={{ __html: teacherChecklist }}
      ></div>
      <ToastContainer />
      <div className="container-fluid">
        <div className="vorbereitungs">
          <div className="user_detail">
            <div className="vorbereitung_title">
              <h2>Vorbereitung</h2>
            </div>
          </div>
          <div className="vorbereitung">
            <div className="vorbereitung_inner">
              <div className="vorbereitung_cards row">
                {abschlussCards?.map(
                  (item, index) =>
                    abschlussValue[index].length > 1 && (
                      <div
                        className="vorbereitung_card col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                        key={index + abschlussValue[index] + item}
                      >
                        <div className="vorbereitung_card_inner">
                          <form className="row">
                            <div className="col-12">
                              <div className="form-check vorbereitung_card_title">
                                <h2>{item}</h2>
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="gridCheck"
                                  checked={abschlussValue[index]
                                    .map((checkData, checkIndex) => {
                                      if (
                                        abschlussData.includes(checkData) &&
                                        checkData !== ""
                                      ) {
                                        return true;
                                      }
                                      if (
                                        !abschlussData.includes(checkData) &&
                                        checkData !== ""
                                      ) {
                                        return false;
                                      }
                                      if (checkData === "") {
                                        return true;
                                      }
                                    })
                                    .every((element) => element === true)}
                                  onChange={() => {
                                    const newArray = abschlussData?.filter(
                                      (element) =>
                                        !abschlussValue[index].includes(
                                          element
                                        ) && element
                                    );

                                    if (
                                      abschlussValue[index]
                                        .map((checkData, checkIndex) => {
                                          if (
                                            abschlussData.includes(checkData) &&
                                            checkData !== ""
                                          ) {
                                            return true;
                                          }
                                          if (
                                            !abschlussData.includes(
                                              checkData
                                            ) &&
                                            checkData !== ""
                                          ) {
                                            return false;
                                          }
                                          if (checkData === "") {
                                            return true;
                                          }
                                        })
                                        .every((element) => element === true)
                                    ) {
                                      setAbschlussData(newArray);
                                    } else {
                                      setAbschlussData(newArray);
                                      setAbschlussData(
                                        abschlussData.concat(
                                          abschlussValue[index]
                                        )
                                      );
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="vorbereitung_detail">
                                {abschlussTitle[index]?.map(
                                  (titleData, titleIndex) =>
                                    abschlussValue[index][titleIndex] !==
                                      "" && (
                                      <div
                                        className="form-check"
                                        key={titleIndex}
                                      >
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          id="gridCheck"
                                          checked={abschlussData.includes(
                                            abschlussValue[index][titleIndex]
                                          )}
                                          onChange={() => {
                                            if (
                                              abschlussData.includes(
                                                abschlussValue[index][
                                                  titleIndex
                                                ]
                                              )
                                            ) {
                                              setAbschlussData(
                                                abschlussData.filter((item) => {
                                                  return (
                                                    abschlussValue[index][
                                                      titleIndex
                                                    ] !== item
                                                  );
                                                })
                                              );
                                            } else {
                                              setAbschlussData([
                                                ...abschlussData,
                                                abschlussValue[index][
                                                  titleIndex
                                                ],
                                              ]);
                                            }
                                          }}
                                        />
                                        <label
                                          className="form-check-label"
                                          htmlFor="gridCheck"
                                        >
                                          {titleData}
                                        </label>
                                      </div>
                                    )
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    )
                )}
                <div className="vorbereitung_card col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="submit_btn">
                    <Link
                      className="btn btn_primary"
                      href="#"
                      onClick={submitData}
                    >
                      SUBMIT
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChecklistTeacherVorbereitung;
