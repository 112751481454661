import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./ApplyCourse.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const ApplyCourse = () => {
  const [data, setData] = useState({});
  const { option2 } = useParams();

  const courseDetails = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");

    await axios
      .post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getCourseDetailsByCourseIdapply&school_id=${localStorage.getItem(
          "session_school_id"
        )}&level_id=${localStorage.getItem(
          "session_user_level_id"
        )}&course_id=${option2}&from_app_new=1&student_id=${localStorage.getItem(
          "session_user_id"
        )}&status=1`
      )
      .then((resp) => {
        setData(resp.data.data);
      });
  };

  // const addWishList = async (courseTitle) => {
  //   const login_cookie = localStorage.getItem("session_user_login_cookie");
  //   const login_user_id = localStorage.getItem("session_user_id");
  //   const login_action_type = localStorage.getItem("session_user_type");

  //   await axios
  //     .post(
  //       `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=enrollprovisional&school_id=${localStorage.getItem(
  //         "session_school_id"
  //       )}&level_id=${localStorage.getItem(
  //         "session_user_level_id"
  //       )}&course_id=${option2}&user_id=${localStorage.getItem(
  //         "session_user_id"
  //       )}&course_title=${courseTitle}&status=1`
  //     )
  //     .then((resp) => {
  //       if (resp.data.status === true) {
  //         toast("We're sorry. Course is full.");
  //       }
  //       if (resp.data.status === false) {
  //         toast(resp.data.message);
  //       }
  //     });
  // };

  const enrollList = async (courseTitle) => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");

    await axios
      .post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=enrollcourse&school_id=${localStorage.getItem(
          "session_school_id"
        )}&level_id=${localStorage.getItem(
          "session_user_level_id"
        )}&course_id=${option2}&user_id=${localStorage.getItem(
          "session_user_id"
        )}&course_title=${courseTitle}&status=1`
      )
      .then((resp) => {
        if (resp.data.status === true) {
          toast("ENROLLED SUCCESSFULLY");
          courseDetails();
        }

        if (resp.data.status === false) {
          toast(resp.data.message);
        }
      });
  };

  useEffect(() => {
    courseDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="apply_course_section">
      <ToastContainer />
      <div className="container-fluid">
        <div className="user_detail">
          <div className="apply_course_title">
            <h2>Apply Course</h2>
          </div>
        </div>
        {Object.values(data)?.map((item, index) => (
          <div key={index}>
            <div className="apply_course_inner">
              <div className="apply_course_card row">
                <div className="my_courses_card col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="apply_course_card_inner">
                    <div className="apply_course_card_title">
                      <h2>{item?.course_title}</h2>
                    </div>
                    <div className="apply_course_detail">
                      <ul className="apply_course_list">
                        <li className="apply_course_item">
                          <span>Phase :</span>
                          <span>
                            {item?.course_level_number} ({item?.level_name})
                          </span>
                        </li>
                        <li className="apply_course_item">
                          <span>Starting Time :</span>
                          <span>{item.class_start_time}</span>
                        </li>
                        <li className="apply_course_item">
                          <span>Ending Time :</span>
                          <span>{item.class_end_time}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="my_courses_card col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="apply_course_card_inner">
                    <div className="apply_course_detail">
                      <h3>Available Slot</h3>

                      <div className="space_for_male">
                        <p>Male :</p>
                        <div className="select_slot">
                          <form>
                            {Array(Math.floor(item?.male_female_equal_seats))
                              .fill(0)
                              .map((itemNumber, indexNumber) => (
                                <div className="form-check" key={indexNumber}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="gridCheck"
                                    checked={item.male_count >= 1 + indexNumber}
                                  />
                                </div>
                              ))}
                          </form>
                        </div>
                      </div>
                      <div className="space_for_female">
                        <p>Female :</p>
                        <div className="select_slot">
                          <form>
                            {Array(Math.floor(item?.male_female_equal_seats))
                              .fill(0)
                              .map((itemNumber, indexNumber) => (
                                <div className="form-check" key={indexNumber}>
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="gridCheck"
                                    checked={
                                      item.female_count >= 1 + indexNumber
                                    }
                                  />
                                </div>
                              ))}
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="apply_course_btn">
                {localStorage.getItem("session_user_gender") === "Female" &&
                  item?.female_count - item?.male_count <= 2 &&
                  item?.female_count < item?.male_female_equal_seats && (
                    <div className="enroll_btn">
                      <Link
                        className="btn btn_primary"
                        to="#"
                        onClick={() => enrollList(item?.course_title)}
                      >
                        Enroll
                      </Link>
                    </div>
                  )}

                {localStorage.getItem("session_user_gender") !== "Female" &&
                  item?.male_count - item?.female_count <= 2 &&
                  item?.male_count < item?.male_female_equal_seats && (
                    <div className="enroll_btn">
                      <Link
                        className="btn btn_primary"
                        to="#"
                        onClick={() => enrollList(item?.course_title)}
                      >
                        Enroll
                      </Link>
                    </div>
                  )}
                {/*<div className="add_waiting_btn">
                  <Link
                    className="btn btn_primary"
                    to="#"
                    onClick={() => addWishList(item?.course_title)}
                  >
                    Add to Waiting list
                  </Link>
                  </div>*/}
                <div className="enroll_btn">
                  {item?.male_count >= item?.male_female_equal_seats ||
                  item?.female_count >= item?.male_female_equal_seats ? (
                    <Link
                      className="btn btn_primary"
                      to="#"
                      onClick={() => {
                        toast("YOU CANNOT ENROLL SEATS ARE FULL");
                      }}
                    >
                      Enroll As Couple
                    </Link>
                  ) : (
                    <Link
                      className="btn btn_primary"
                      to={`/Home/course/enrollCouple/course_id=${item.course_id}&course_title=${item.course_title}`}
                    >
                      Enroll As Couple
                    </Link>
                  )}
                </div>
              </div>
            </div>

            <div className="apply_data_table">
              {Object.values(item.course_goal_first_four)?.map(
                (firstFourData, firstFourIndex) => (
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col" colSpan="3">
                            {
                              Object.keys(item.course_goal_first_four)[
                                firstFourIndex
                              ]
                            }
                          </th>
                        </tr>
                      </thead>
                      <tbody className="table-group-divider">
                        {firstFourData?.map(
                          (innerTableData, innerTableIndex) => (
                            <tr
                              dangerouslySetInnerHTML={{
                                __html: innerTableData.title,
                              }}
                              key={innerTableIndex}
                            ></tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                )
              )}

              {Object.values(item.course_goal_rest_after_four)?.map(
                (firstFourData, firstFourIndex) => (
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col" colSpan="3">
                            {
                              Object.keys(item.course_goal_first_four)[
                                firstFourIndex
                              ]
                            }
                          </th>
                        </tr>
                      </thead>
                      <tbody className="table-group-divider">
                        {Object.values(firstFourData)?.map(
                          (innerTableData, innerTableIndex) => (
                            <tr
                              dangerouslySetInnerHTML={{
                                __html: innerTableData.title,
                              }}
                              key={innerTableIndex}
                            ></tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                )
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ApplyCourse;
