import React from "react";
import { Link } from "react-router-dom";
import profile from "../../../assets/images/icons/profile.svg";
import package_icon from "../../../assets/images/icons/package-icon.svg";
import cource_overview from "../../../assets/images/icons/cource-overview.svg";
import twint_icon from "../../../assets/images/icons/twint-icon.svg";
import "./ProfileBlocks.scss";

const ProfileBlocks = () => {
  return (
    <div className="profile_blocks">
      <div className="container-fluid">
        <div className="profile_blocks_inner">
          <div className="profile_blocks_group">
            <div className="row">
              <div className="profile_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                <Link
                  className="profile_block_inner"
                  to="/Home/profile/personalInfo"
                >
                  <div className="profile_block_icon">
                    <img className="img-fluid" src={profile} alt="profile" />
                  </div>
                  <h2>PERSONAL INFORMATION</h2>
                </Link>
              </div>
              <div className="profile_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                <Link
                  className="profile_block_inner"
                  to="/Home/profile/packages"
                >
                  <div className="profile_block_icon">
                    <img
                      className="img-fluid"
                      src={package_icon}
                      alt="package_icon"
                    />
                  </div>
                  <h2>MY PACKAGE</h2>
                </Link>
              </div>
              <div className="profile_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                <Link
                  className="profile_block_inner"
                  to="/Home/profile/courses"
                >
                  <div className="profile_block_icon">
                    <img
                      className="img-fluid"
                      src={cource_overview}
                      alt="cource_overview"
                    />
                  </div>
                  <h2>MY COURSES</h2>
                </Link>
              </div>
              {localStorage.getItem("session_student_teacher") !== "0" &&
                localStorage.getItem("is_external_teacher") !== "1" && (
                  <div className="profile_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                    <Link
                      className="profile_block_inner"
                      to="/Home/profile/teacher"
                    >
                      <div className="profile_block_icon">
                        <img
                          className="img-fluid"
                          src={profile}
                          alt="profile"
                        />
                      </div>
                      <h2>TEACHER</h2>
                    </Link>
                  </div>
                )}
              <div className="profile_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                <Link
                  className="profile_block_inner"
                  to="/Home/profile/openBill"
                >
                  <div className="profile_block_icon">
                    <img
                      className="img-fluid"
                      src={twint_icon}
                      alt="twint_icon"
                    />
                  </div>
                  <h2>OPENBILL</h2>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileBlocks;
