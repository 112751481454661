import React, { useEffect, useState } from "react";
import axios from "axios";
import "./ViewNotes.scss";
import { useParams } from "react-router-dom";

const ViewNotes = () => {
  const [data, setData] = useState(null);
  const { option2 } = useParams();

  const checklistReportDetails = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const user_id = localStorage.getItem("session_user_id");
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("task_id", option2);

    await axios
      .post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getTaskNotesHistory&status=1`,
        formData
      )
      .then((resp) => {
        setData(resp.data);
      });
  };
  useEffect(() => {
    checklistReportDetails();
  }, [data]);
  return (
    <div className="checklist_report_section">
      <div className="container-fluid">
        <div className="user_detail">
          <div className="checklist_report_title">
            <h2>Task Notes</h2>
          </div>
        </div>
        <div className="checklist_report_inner">
          <div className="checklist_report_table">
            <div className="table-responsive">
              <table className="table table-bordered">
                <tbody dangerouslySetInnerHTML={{ __html: data }}></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewNotes;
