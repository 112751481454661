import React from "react";
import "./Topbar.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import logo from "../../assets/images/logo/logo@3x.png";
import scan from "../../assets/images/icons/scan.svg";
import back_btn from "../../assets/images/icons/back_btn.svg";

const Topbar = ({ toogle, setToogle, qrSannerTrue, toogleTop }) => {
  const { option } = useParams();
  const { option1 } = useParams();
  const { option2 } = useParams();
  const navigate = useNavigate();
  const date = new Date();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const formattedDate = `${date.getDate()}-${
    monthNames[date.getMonth()]
  }-${date.getFullYear()}`;
  return (
    <header
      className={
        option !== "checkIn"
          ? "topbar"
          : toogleTop
          ? "topbarRed"
          : "topbarGreen"
      }
    >
      <nav className="d-flex flex-wrap top-navbar navbar-expand-md">
        <div className="navbar-header">
          <div
            id="sidebar_btn"
            className={!toogle ? "collapsed" : ""}
            onClick={() => {
              setToogle(!toogle);
            }}
          >
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </div>
          <Link href="#" className="navbar-brand">
            <img className="img-fluid" src={logo} alt="logo" />
          </Link>
        </div>
        <div className="navbar-collapse">
          <div className="navbar-nav">
            <div className="nav-item date_version">
              <span className="date text-uppercase">{formattedDate} </span>(
              <span className="version">VER 2.3.2</span>)
            </div>
            <div className="nav-item scanner_block">
              {option === "dashboard" && (
                <Link className="scanner" href="#">
                  <img
                    className="img-fluid"
                    src={scan}
                    onClick={qrSannerTrue}
                    alt="scan"
                  />
                </Link>
              )}
              {!(option === "dashboard" && !option1 && !option2) && (
                <Link className="scanner" href="#">
                  <img
                    className="img-fluid"
                    src={back_btn}
                    onClick={() => {
                      navigate(-1);
                    }}
                    alt="back"
                  />
                </Link>
              )}
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Topbar;
