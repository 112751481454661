import React, { useEffect, useState } from "react";
import axios from "axios";
import "./MyCourses.scss";
import { Link } from "react-router-dom";

const MyCourses = () => {
  const [courseData, setCourseData] = useState(null);
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const conditionalDays = ["so", "mo", "di", "mi", "do", "fr", "sa"];

  async function myCourseDetails() {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const school_id = localStorage.getItem("session_school_id");
    const user_id = localStorage.getItem("session_user_id");
    await axios
      .post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getStudentCourseListDayWise&user_id=${user_id}&school_id=${school_id}&is_my_course=1&status=1`
      )
      .then((resp) => {
        setCourseData(resp.data.data);
      });
  }

  useEffect(() => {
    myCourseDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="my_courses_section">
      <div className="container-fluid">
        <div className="user_detail">
          <div className="my_courses_title">
            <h2>My Courses</h2>
          </div>
        </div>
        <div className="accordion" id="accordionExample">
          {courseData !== null &&
            Object.values(courseData)?.map((item, index) => (
              <div className="accordion-item" key={index}>
                <h2 className="accordion-header" id={`headingOne${index}`}>
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapseOne${index}`}
                    aria-expanded="false"
                    aria-controls={`collapseOne${index}`}
                  >
                    {Object.keys(courseData)[index] === ""
                      ? localStorage.getItem("session_language") === "en"
                        ? "Main Course"
                        : "Hauptkurs"
                      : Object.keys(courseData)[index] === "Fixed"
                      ? localStorage.getItem("session_language") === "en"
                        ? "Additional Fix Course"
                        : "Zusatzkurs"
                      : Object.keys(courseData)[index] === "One Time"
                      ? localStorage.getItem("session_language") === "en"
                        ? Object.keys(courseData)[index]
                        : "Einmal"
                      : Object.keys(courseData)[index]}
                  </button>
                </h2>
                <div
                  id={`collapseOne${index}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`headingOne${index}`}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div className="my_courses">
                      <div className="my_courses_inner">
                        <div className="my_courses_card_status">
                          <ul
                            className="nav nav-pills mb-3"
                            id="pills-tab"
                            role="tablist"
                          >
                            {Object.values(item)?.map((itemDay, indexDay) => (
                              <li
                                className="nav-item"
                                role="presentation"
                                key={indexDay}
                              >
                                <Link
                                  href="#"
                                  className={`nav-link ${
                                    indexDay === 0 && "active"
                                  }`}
                                  id={`pills-${index}-salsa-${indexDay}-tab`}
                                  data-bs-toggle="pill"
                                  data-bs-target={`#pills-${index}-salsa-${indexDay}`}
                                  type="button"
                                  role="tab"
                                  aria-controls={`pills-${index}-salsa-${indexDay}`}
                                  aria-selected="true"
                                >
                                  {
                                    days[
                                      conditionalDays.indexOf(
                                        Object.keys(item)[indexDay]
                                      )
                                    ]
                                  }
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                        {Object.values(item)?.map((itemDay, indexDay) => (
                          <div
                            key={indexDay}
                            className={`tab-pane fade show ${
                              indexDay === 0 && "active"
                            }`}
                            id={`pills-${index}-salsa-${indexDay}`}
                            role="tabpanel"
                            aria-labelledby={`pills-${index}-salsa-${indexDay}-tab`}
                            tabIndex={`${indexDay}`}
                          >
                            <div className="my_courses_cards row">
                              {Object.values(itemDay)?.map(
                                (insideDay, insideDayIndex) =>
                                  Object.values(insideDay)?.map(
                                    (mainValues, valuesIndex) => (
                                      <Link
                                        to={`/Home/profile/courses/${mainValues.course_id}`}
                                        className="my_courses_card col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12"
                                        key={valuesIndex}
                                      >
                                        <div className="my_courses_card_inner">
                                          <div className="my_courses_card_title">
                                            <h2>
                                              {" "}
                                              {
                                                Object.keys(itemDay)[
                                                  insideDayIndex
                                                ]
                                              }
                                            </h2>
                                          </div>
                                          <div className="my_courses_detail">
                                            <ul className="my_courses_list">
                                              <li className="my_courses_item">
                                                <span>Advanced Level :</span>
                                                <span>
                                                  {
                                                    mainValues?.course_level_number
                                                  }
                                                </span>
                                              </li>
                                              <li className="my_courses_item">
                                                <span>Starting Time :</span>
                                                <span>
                                                  {mainValues?.class_start_time}
                                                </span>
                                              </li>
                                              <li className="my_courses_item">
                                                <span>Ending Time :</span>
                                                <span>
                                                  {mainValues?.class_end_time}
                                                </span>
                                              </li>
                                              <li className="my_courses_item">
                                                <span>Room :</span>
                                                <span>
                                                  {mainValues?.course_room}
                                                </span>
                                              </li>
                                              <li className="my_courses_item">
                                                <span>Teacher 1 :</span>
                                                <span>
                                                  {mainValues?.teacher1}
                                                </span>
                                              </li>
                                              <li className="my_courses_item">
                                                <span>Teacher 2 :</span>
                                                <span>
                                                  {mainValues?.teacher2}
                                                </span>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </Link>
                                    )
                                  )
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default MyCourses;
