import React, { useEffect, useState } from "react";
import axios from "axios";
import "./ChecklistCeo.scss";
import { ToastContainer, toast } from "react-toastify";

const ChecklistCeo = () => {
  const [data, setData] = useState(null);

  const checklistCeoDetails = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const user_id = localStorage.getItem("session_user_id");
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("school_type", "ZH");
    formData.append("location", "abschluss");

    function findAllOkBtn() {
      const allOkBtn = document.querySelectorAll("tr td .ok_btn");
      allOkBtn.forEach((element) => {
        element.addEventListener("click", async function () {
          await axios
            .post(
              `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=addPositiveCheckListPoint&teacher_id=${element.getAttribute(
                "data-teacher_id"
              )}&checklist_id=${element.getAttribute(
                "data-checklist_id"
              )}&ceo_id=${login_user_id}&status=1`
            )
            .then((resp) => {
              checklistCeoDetails();
              toast("Positive Point Added");
            });
        });
      });
    }

    function findAllNotOkBtn() {
      const allOkBtn = document.querySelectorAll("tr td .notok_btn");
      allOkBtn.forEach((element) => {
        element.addEventListener("click", async function () {
          await axios
            .post(
              `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=addNegativeCheckListPoint&teacher_id=${element.getAttribute(
                "data-teacher_id"
              )}&checklist_id=${element.getAttribute(
                "data-checklist_id"
              )}&ceo_id=${login_user_id}&status=1`
            )
            .then((resp) => {
              checklistCeoDetails();
              toast("Negative Point Added");
            });
        });
      });
    }

    await axios
      .post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getCeoCheckListLocationWise&status=1`,
        formData
      )
      .then((resp) => {
        setData(resp.data);

        findAllOkBtn();
        findAllNotOkBtn();
      });
  };
  useEffect(() => {
    checklistCeoDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div className="ceo_checklist_section">
      <ToastContainer />
      <div className="container-fluid">
        <div className="user_detail">
          <div className="ceo_checklist_title">
            <h2>CEO CHECKLIST</h2>
          </div>
        </div>
        <div className="ceo_checklist_inner">
          <div className=""></div>
          <div className="ceo_checklist_table">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Teacher</th>
                    <th>Point Type</th>
                    <th>Select Teacher</th>
                    <th>Room</th>
                    <th>Option</th>
                    <th>Submitted On</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody
                  className="table-group-divider"
                  dangerouslySetInnerHTML={{ __html: data }}
                ></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChecklistCeo;
