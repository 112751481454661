import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Video.scss";
import Play from "../../../assets/images/icons/play-circle.svg";
import Close from "../../../assets/images/icons/close.svg";
import Filter from "../../../assets/images/icons/filter.svg";
import Search from "../../../assets/images/icons/search.svg";
import axios from "axios";

const Video = () => {
  const [searchData, setSearchData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [videoData, setVideoData] = useState([]);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [videoCategoryToogle, setVideoCategoryToogle] = useState(false);
  const [videoDanceTypeToogle, setVideoDanceTypeToogle] = useState(false);
  const [videoDanceCategoryToogle, setVideoDanceCategoryToogle] =
    useState(false);
  const [videoCategoryToogleValue, setVideoCategoryToogleValue] =
    useState(null);
  const [videoDanceTypeToogleValue, setVideoDanceTypeToogleValue] =
    useState(null);
  const [videoDanceCategoryToogleValue, setVideoDanceCategoryToogleValue] =
    useState(null);

  const [level, setLevel] = useState("");
  const [dance, setDance] = useState("");
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState("");

  const getSearchData = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const user_id = localStorage.getItem("session_user_id");
    setLoading(true);

    if (searchData === "") {
      setSearchResults([]);
      setLoading(false);
      return;
    }
    try {
      const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=searchEvent&l=3&p=videoEventList&q=${searchData}&s=2&user_id=${user_id}&status=1`;
      const response = await axios.get(api);
      const resultsArray = Object.entries(response.data).map(([id, name]) => ({
        id,
        name,
      }));
      setSearchResults(resultsArray);
      setLoading(false);
    } catch (error) {
      setSearchResults([]);
      setLoading(false);
    }
  };

  const getFilterData = async () => {
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("session_user_id"));
    formData.append("user_type", localStorage.getItem("session_user_type"));
    formData.append(
      "user_level",
      localStorage.getItem("session_user_level_id")
    );

    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getVideosSearch&level_id=${level}&video_dance=${dance}&video_uncategory=${category}&video_title=${title}&status=1`;

    await axios.post(api, formData).then((resp) => {
      if (resp.data.status === false) {
        getVideoData();
      } else {
        setVideoData(resp.data);
      }
    });
  };

  const handleSuggestionClick = (result) => {
    setSelectedSuggestion(result);
  };

  const filteredVideoData = videoData?.filter(
    (video) => video.video_id === selectedSuggestion?.id
  );

  const getVideoData = async () => {
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("session_user_id"));

    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getVideos&status=1`;

    await axios.post(api, formData).then((resp) => {
      console.log(resp, "RESP");
      if (resp.data.status === false) {
        return;
      } else {
        setVideoData(resp.data);
      }
    });
  };

  useEffect(() => {
    getSearchData();
    getVideoData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchData]);

  return (
    <>
      <div className="video_list_section">
        <div className="container-fluid">
          <div className="user_detail">
            <div className="video_list_title">
              <h2>Video list</h2>
            </div>
          </div>
          <div className="video_list">
            <div className="video_list_inner">
              <div className="search_filter_block">
                <div className="search_block">
                  <form role="search">
                    <div className="input-group add-on">
                      <input
                        className="form-control"
                        placeholder="Search"
                        name="srch-term"
                        id="srch-term"
                        type="search"
                        value={searchData}
                        onChange={(e) => {
                          setSearchData(e.target.value);
                          setShowResult(true);
                        }}
                      />
                      <div className="input-group-btn">
                        <button
                          className="btn btn-default"
                          onClick={(e) => {
                            e.preventDefault();
                            getSearchData();
                          }}
                        >
                          <img className="img-fluid" src={Search} alt="" />
                        </button>
                      </div>
                    </div>
                  </form>

                  {/* Search Listing  */}
                  {showResult && searchData.length > 0 && (
                    <div className="video_search_list">
                      {loading ? (
                        <span className="mt-5">Loading...</span>
                      ) : (
                        <ul>
                          {searchResults.map((result) => (
                            <li
                              key={result.id}
                              onClick={() => {
                                handleSuggestionClick(result);
                                setShowResult(false);
                              }}
                              className={
                                result === selectedSuggestion ? "selected" : ""
                              }
                            >
                              <Link to="#">{result.name}</Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  )}
                </div>

                <div className="filter_block">
                  <Link
                    type="button"
                    className="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal2"
                    to="#"
                  >
                    <img className="img-fluid" src={Filter} alt="" />
                  </Link>
                </div>

                {searchData.length > 0 && selectedSuggestion ? (
                  <div className="video_list_cards row mt-5">
                    {filteredVideoData?.map((item, index) => (
                      <div key={index} className="video_list_card col-12">
                        <div className="video_list_card_inner">
                          <div className="video_list_card_row">
                            <div className="video_list_card_column_1">
                              <div className="video_list_card_title">
                                <h3>{item?.video_title}</h3>
                              </div>
                              <div className="video_list_detail">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item?.video_description,
                                  }}
                                ></div>
                              </div>
                            </div>

                            <div className="video_list_card_column_2">
                              <div className="delete-icon">
                                <Link
                                  to={`${item.video_id}`}
                                  onClick={() => {
                                    setCurrentVideo(item);
                                  }}
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                >
                                  <img
                                    className="img-fluid"
                                    src={Play}
                                    alt=""
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="video_list_cards row mt-5">
                    {videoData?.map((item, index) => (
                      <div key={index} className="video_list_card col-12">
                        <div className="video_list_card_inner">
                          <div className="video_list_card_row">
                            <div className="video_list_card_column_1">
                              <div className="video_list_card_title">
                                <h3>{item?.video_title}</h3>
                              </div>
                              <div className="video_list_detail">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item?.video_description,
                                  }}
                                ></div>
                              </div>
                            </div>

                            <div className="video_list_card_column_2">
                              <div className="delete-icon">
                                <Link
                                  to={`${item.video_id}`}
                                  onClick={() => {
                                    setCurrentVideo(item);
                                  }}
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                >
                                  <img
                                    className="img-fluid"
                                    src={Play}
                                    alt=""
                                  />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="video_list_modal">
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <Link
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <img className="img-fluid" src={Close} alt="" />
                  </Link>
                </div>
                <div className="modal-body">
                  <div className="date_time_title">
                    <div className="date_time">
                      <div className="date">
                        <p>{currentVideo?.video_cretaed_date.slice(0, 11)}</p>
                      </div>
                      <div className="time">
                        <p>{currentVideo?.video_cretaed_date.slice(11)}</p>
                      </div>
                    </div>
                    <div className="title_detail">
                      <h3>{currentVideo?.video_title}</h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: currentVideo?.video_description,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="video_block">
                    {currentVideo?.video_url.includes("<iframe") ? (
                      <iFrame
                        src={`https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=6362c9886d97d&login_user_id=227&login_action_type=student&f=getVimeoAuth&code=${
                          currentVideo?.video_url.match(
                            /\/\/player\.vimeo\.com\/video\/(\d+)/
                          )[1]
                        }&status=1`}
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                      ></iFrame>
                    ) : (
                      <p>NO VIDEO</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="filter_modal">
          <div
            className="modal fade"
            id="exampleModal2"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h3>Filter Option</h3>
                  <Link
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <img className="img-fluid" src={Close} alt="" />
                  </Link>
                </div>
                <div className="modal-body">
                  <form className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <div className="category-select">
                        <button
                          className={`btn dropdown-toggle3 ${
                            videoCategoryToogle && "show"
                          }`}
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => {
                            setVideoCategoryToogle(!videoCategoryToogle);
                            setVideoDanceTypeToogle(false);
                            setVideoDanceCategoryToogle(false);
                          }}
                        >
                          {videoCategoryToogleValue === null
                            ? "Select Category"
                            : videoCategoryToogleValue}
                        </button>
                        <ul
                          className={`dropdown-menu ${
                            videoCategoryToogle && "show"
                          }`}
                        >
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => {
                                setVideoCategoryToogle(false);
                                setVideoCategoryToogleValue("Beginners");
                                setLevel(1);
                              }}
                            >
                              Beginners
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => {
                                setVideoCategoryToogle(false);
                                setVideoCategoryToogleValue("Intermediate");
                                setLevel(2);
                              }}
                            >
                              Intermediate
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => {
                                setVideoCategoryToogle(false);
                                setVideoCategoryToogleValue("Advanced");
                                setLevel(3);
                              }}
                            >
                              Advanced
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => {
                                setVideoCategoryToogle(false);
                                setVideoCategoryToogleValue("VIP");
                                setLevel(4);
                              }}
                            >
                              VIP
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <div className="dance-select">
                        <button
                          className={`btn dropdown-toggle2 ${
                            videoDanceTypeToogle && "show"
                          }`}
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => {
                            setVideoCategoryToogle(false);
                            setVideoDanceTypeToogle(!videoDanceTypeToogle);
                            setVideoDanceCategoryToogle(false);
                          }}
                        >
                          {videoDanceTypeToogleValue === null
                            ? "Select Dance Type"
                            : videoDanceTypeToogleValue}
                        </button>
                        <ul
                          className={`dropdown-menu ${
                            videoDanceTypeToogle && "show"
                          }`}
                        >
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => {
                                setVideoDanceTypeToogle(false);
                                setVideoDanceTypeToogleValue("Salsa");
                                setDance(0);
                              }}
                            >
                              Salsa
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => {
                                setVideoDanceTypeToogle(false);
                                setVideoDanceTypeToogleValue("Bachata");
                                setDance(1);
                              }}
                            >
                              Bachata
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => {
                                setVideoDanceTypeToogle(false);
                                setVideoDanceTypeToogleValue("Kizomba");
                                setDance(2);
                              }}
                            >
                              Kizomba
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => {
                                setVideoDanceTypeToogle(false);
                                setVideoDanceTypeToogleValue("Manstyle");
                                setDance(3);
                              }}
                            >
                              Manstyle
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => {
                                setVideoDanceTypeToogle(false);
                                setVideoDanceTypeToogleValue("Ladystyle");
                                setDance(4);
                              }}
                            >
                              Ladystyle
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <div className="dance-category">
                        <button
                          className={`btn dropdown-toggle1 ${
                            videoDanceCategoryToogle && "show"
                          }`}
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          onClick={() => {
                            setVideoCategoryToogle(false);
                            setVideoDanceTypeToogle(false);
                            setVideoDanceCategoryToogle(
                              !videoDanceCategoryToogle
                            );
                          }}
                        >
                          {videoDanceCategoryToogleValue === null
                            ? "Select Dance Category"
                            : videoDanceCategoryToogleValue}
                        </button>
                        <ul
                          className={`dropdown-menu ${
                            videoDanceCategoryToogle && "show"
                          }`}
                        >
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => {
                                setVideoDanceCategoryToogle(false);
                                setVideoDanceCategoryToogleValue("Pasitos");
                                setCategory(3);
                              }}
                            >
                              Pasitos
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => {
                                setVideoDanceCategoryToogle(false);
                                setVideoDanceCategoryToogleValue(
                                  "Puertoriquena breaking on 1"
                                );
                                setCategory(0);
                              }}
                            >
                              Puertoriquena breaking on 1
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => {
                                setVideoDanceCategoryToogle(false);
                                setVideoDanceCategoryToogleValue(
                                  "NY Style breaking on 2"
                                );
                                setCategory(9);
                              }}
                            >
                              NY Style breaking on 2
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => {
                                setVideoDanceCategoryToogle(false);
                                setVideoDanceCategoryToogleValue("Traditional");
                                setCategory(5);
                              }}
                            >
                              Traditional
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => {
                                setVideoDanceCategoryToogle(false);
                                setVideoDanceCategoryToogleValue("Fusion");
                                setCategory(6);
                              }}
                            >
                              Fusion
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => {
                                setVideoDanceCategoryToogle(false);
                                setVideoDanceCategoryToogleValue("Cubana");
                                setCategory(1);
                              }}
                            >
                              Cubana
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => {
                                setVideoDanceCategoryToogle(false);
                                setVideoDanceCategoryToogleValue("Rudea");
                                setCategory(4);
                              }}
                            >
                              Rudea
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => {
                                setVideoDanceCategoryToogle(false);
                                setVideoDanceCategoryToogleValue("Others");
                                setCategory(2);
                              }}
                            >
                              Others
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => {
                                setVideoDanceCategoryToogle(false);
                                setVideoDanceCategoryToogleValue("Basic");
                                setCategory(7);
                              }}
                            >
                              Basic
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="dropdown-item"
                              to="#"
                              onClick={() => {
                                setVideoDanceCategoryToogle(false);
                                setVideoDanceCategoryToogleValue("Combo");
                                setCategory(8);
                              }}
                            >
                              Combo
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                      <div className="some-title">
                        <input
                          type="search"
                          className="form-control"
                          id="inputTitle"
                          placeholder="Search..."
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn_primary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setVideoCategoryToogleValue(null);
                      setVideoDanceTypeToogleValue(null);
                      setVideoDanceCategoryToogleValue(null);
                      setLevel("");
                      setDance("");
                      setCategory("");
                      setTitle("");
                      getVideoData();
                    }}
                  >
                    Clear Filter
                  </button>
                  <button
                    className="btn btn_primary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setSelectedSuggestion(null);
                      setSearchData("");
                      getFilterData();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Video;
