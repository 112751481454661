import React from "react";
import video_camera from "../../../assets/images/icons/video-camera.svg";
import music from "../../../assets/images/icons/music.svg";
import exercise from "../../../assets/images/icons/exercise.svg";
import newspaper from "../../../assets/images/icons/newspaper.svg";
import { Link } from "react-router-dom";
import "./MediaBlocks.scss";

const MediaBlocks = ({ showVideo }) => {
  return (
    <div className="media_blocks">
      <div className="container-fluid">
        <div className="media_blocks_inner">
          <div className="media_blocks_group">
            <div className="row">
              {showVideo !== 0 && (
                <div className="media_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                  <Link className="media_block_inner" to="/Home/media/video">
                    <div className="media_block_icon">
                      <img
                        className="img-fluid"
                        src={video_camera}
                        alt="video_camera"
                      />
                    </div>
                    <h2>VIDEO</h2>
                  </Link>
                </div>
              )}
              <div className="media_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                <Link
                  className="media_block_inner"
                  to="/Home/media/musicPlaylist"
                >
                  <div className="media_block_icon">
                    <img className="img-fluid" src={music} alt="music" />
                  </div>
                  <h2>MUSIC PLAYLIST</h2>
                </Link>
              </div>
              <div className="media_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                <Link className="media_block_inner" to="/Home/media/exercise">
                  <div className="media_block_icon">
                    <img className="img-fluid" src={exercise} alt="exercise" />
                  </div>
                  <h2>EXERCISE</h2>
                </Link>
              </div>
              <div className="media_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                <Link className="media_block_inner" to="/Home/media/news">
                  <div className="media_block_icon">
                    <img
                      className="img-fluid"
                      src={newspaper}
                      alt="newspaper"
                    />
                  </div>
                  <h2>NEWS</h2>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaBlocks;
