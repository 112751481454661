import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./CourseDetail.scss";
import Close from "../../../assets/images/icons/close.svg";
import axios from "axios";

const CourseDetail = () => {
  const [data, setData] = useState({});
  const { option2 } = useParams();

  function removeImg() {
    var imgToRemove = document.querySelector(
      'img[src="images/cal-icon_g.png"]'
    );

    if (imgToRemove) {
      imgToRemove.parentNode.removeChild(imgToRemove);
    }
  }
  const courseDetails = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");

    await axios
      .post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getCourseDetailsByCourseId&school_id=${localStorage.getItem(
          "session_school_id"
        )}&level_id=${localStorage.getItem(
          "session_user_level_id"
        )}&course_id=${option2}&user_id=${localStorage.getItem(
          "session_user_id"
        )}&from_app_new=1&status=1`
      )
      .then((resp) => {
        setData(resp.data);
        removeImg();
      });
  };

  const [videoData, setVideoData] = useState([]);
  const [currentVideo, setCurrentVideo] = useState(null);

  const getVideoData = async () => {
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("session_user_id"));

    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getVideos&status=1`;

    await axios.post(api, formData).then((resp) => {
      setVideoData(resp.data);
      removeImg();
    });
  };

  const searchAndShowVideo = (detail) => {
    const filteredVideoData = videoData.filter(
      (video) => video.video_id === detail
    );
    setCurrentVideo(filteredVideoData[0]);
  };

  useEffect(() => {
    courseDetails();
    getVideoData();
    removeImg();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="apply_course_section">
      <div className="container-fluid">
        <div className="user_detail">
          <div className="apply_course_title">
            <h2>Course Detail</h2>
          </div>
        </div>
        <div className="video_list_modal">
          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog ">
              <div className="modal-content">
                <div className="modal-header">
                  <Link
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <img className="img-fluid" src={Close} alt="" />
                  </Link>
                </div>
                <div className="modal-body">
                  <div className="date_time_title">
                    <div className="date_time">
                      <div className="date">
                        <p>{currentVideo?.video_cretaed_date.slice(0, 11)}</p>
                      </div>
                      <div className="time">
                        <p>{currentVideo?.video_cretaed_date.slice(11)}</p>
                      </div>
                    </div>
                    <div className="title_detail">
                      <h3>{currentVideo?.video_title}</h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: currentVideo?.video_description,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="video_block">
                    {currentVideo?.video_url.includes("<iframe") ? (
                      <iFrame
                        src={`https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=6362c9886d97d&login_user_id=227&login_action_type=student&f=getVimeoAuth&code=${
                          currentVideo?.video_url.match(
                            /\/\/player\.vimeo\.com\/video\/(\d+)/
                          )[1]
                        }&status=1`}
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                      ></iFrame>
                    ) : (
                      <p>NO VIDEO</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {Object.values(data)?.map(
          (item, index) =>
            index === 0 && (
              <div key={index}>
                <div className="apply_course_inner">
                  <div className="apply_course_card row">
                    <div className="my_courses_card col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="apply_course_card_inner">
                        <div className="apply_course_card_title">
                          <h2>{item?.course_title}</h2>
                        </div>
                        <div className="apply_course_detail">
                          <ul className="apply_course_list">
                            <li className="apply_course_item">
                              <span>Description :</span>
                              <span>{item?.course_description}</span>
                            </li>
                            <li className="apply_course_item">
                              <span>Phase :</span>
                              <span>
                                {item?.course_level_number} ({item?.level_name})
                              </span>
                            </li>
                            <li className="apply_course_item">
                              <span>Starting Time :</span>
                              <span>{item.class_start_time}</span>
                            </li>
                            <li className="apply_course_item">
                              <span>Ending Time :</span>
                              <span>{item.class_end_time}</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="apply_data_table">
                  {Object.values(item.course_goal_first_four)?.map(
                    (firstFourData, firstFourIndex) => (
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col" colSpan="3">
                                {
                                  Object.keys(item.course_goal_first_four)[
                                    firstFourIndex
                                  ]
                                }
                              </th>
                            </tr>
                          </thead>
                          <tbody className="table-group-divider">
                            {firstFourData?.map(
                              (innerTableData, innerTableIndex) => (
                                <>
                                  {innerTableData.video_id !== undefined ? (
                                    <tr
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      onClick={() => {
                                        searchAndShowVideo(
                                          innerTableData.video_id
                                        );
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: innerTableData.title,
                                      }}
                                      key={innerTableIndex}
                                    ></tr>
                                  ) : (
                                    <tr
                                      dangerouslySetInnerHTML={{
                                        __html: innerTableData.title,
                                      }}
                                      key={innerTableIndex}
                                    ></tr>
                                  )}
                                </>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    )
                  )}

                  {Object.values(item.course_goal_rest_after_four)?.map(
                    (firstFourData, firstFourIndex) => (
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col" colSpan="3">
                                {
                                  Object.keys(item.course_goal_first_four)[
                                    firstFourIndex
                                  ]
                                }
                              </th>
                            </tr>
                          </thead>
                          <tbody className="table-group-divider">
                            {Object.values(firstFourData)?.map(
                              (innerTableData, innerTableIndex) => (
                                <>
                                  {innerTableData.video_id !== undefined ? (
                                    <tr
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                      onClick={() => {
                                        searchAndShowVideo(
                                          innerTableData.video_id
                                        );
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: innerTableData.title,
                                      }}
                                      key={innerTableIndex}
                                    ></tr>
                                  ) : (
                                    <tr
                                      dangerouslySetInnerHTML={{
                                        __html: innerTableData.title,
                                      }}
                                      key={innerTableIndex}
                                    ></tr>
                                  )}
                                </>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    )
                  )}
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default CourseDetail;
