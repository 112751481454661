import React, { useEffect, useState } from "react";
import img_usr from "../../../assets/images/personal_info/img_usr.png";
import "./PersonalInfo.scss";
import axios from "axios";

const PersonalInfo = () => {
  const [data, setData] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("salsa");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const getdata = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const login_action_user_type = localStorage.getItem(
      "session_school_login_type"
    );
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getlogininfo&user_id=${login_user_id}&action_type=${login_action_user_type}&status=1`;

    await axios.post(api).then((resp) => {
      setData(resp.data);
      setFirstName(resp?.data?.user_firstname);
      setLastName(resp?.data?.user_lastname);
      setEmail(resp?.data?.user_email);
      setAddress(resp?.data?.user_address);
      setPhone(resp?.data?.user_contact);
    });
  };

  useEffect(() => {
    getdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="personal_info_section">
      <div className="container-fluid">
        <div className="personal_info_title">
          <h2>PERSONAL INFO</h2>
        </div>
        <div className="personal_info_card">
          <div className="personal_info_card_inner">
            <div className="info_name_img">
              <div className="info_img">
                <label className="input-group-text" htmlFor="inputGroupFile01">
                  <img className="img-fluid" src={img_usr} alt="USER" />
                </label>
                <input
                  type="file"
                  className="form-control d-none"
                  id="inputGroupFile01"
                />
              </div>
              <div className="info_name d-md-block d-sm-none">
                <h3 className="text-uppercase">
                  {data?.user_firstname || " "} {data?.user_lastname || " "}
                </h3>
              </div>
            </div>
            <div className="info_form">
              <form className="row">
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                  <label htmlFor="inputFirstname" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputFirstname"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                  <label htmlFor="inputLastname" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputLastname"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                  <label htmlFor="inputEmail" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="inputEmail"
                    placeholder=""
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
                  <label htmlFor="inputPassword" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="inputPassword"
                    placeholder="***********"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <label htmlFor="inputPhone" className="form-label">
                    Phone no
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="inputPhone"
                    placeholder=""
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                    }}
                  />
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <label htmlFor="inputAddress" className="form-label">
                    Address
                  </label>
                  <textarea
                    className="form-control"
                    id="inputAddress"
                    rows="4"
                    placeholder="Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took."
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  ></textarea>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
