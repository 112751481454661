import axios from "axios";
import React, { useEffect, useState } from "react";
import "./Loyalty.scss";
import { Link } from "react-router-dom";
import Close from "../../assets/images/icons/close.svg";
import { toast } from "react-toastify";

const Loyalty = () => {
  const [data, setData] = useState({});
  const [loyalty, setLoyalty] = useState(null);
  const [code, setCode] = useState("");

  const getCheckInDetail = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");

    const formData = new FormData();
    formData.append("user_id", login_user_id);
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getloyalty&status=1`;

    await axios.post(api, formData).then((resp) => {
      setData(resp.data);
      console.log(resp.data);
    });
  };

  const resetLoyalty = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");

    const formData = new FormData();
    formData.append("user_id", login_user_id);
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=saveloyalty&loyalty=0&code=${code}&status=1`;

    await axios.post(api, formData).then((resp) => {
      toast("Loyalty Reset Successfully");
    });
  };

  const saveLoyalty = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");

    const formData = new FormData();
    formData.append("user_id", login_user_id);
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=saveloyalty&loyalty=${loyalty}&code=${code}&status=1`;

    if (code.length === 0) {
      toast("Please Enter Code");
    } else {
      await axios.post(api, formData).then((resp) => {
        toast(resp.data.message);
      });
    }
  };

  useEffect(() => {
    getCheckInDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="loyalty_card_section">
        <div className="container-fluid">
          <div className="user_detail">
            <div className="notifications_title">
              <h2>loyalty card</h2>
            </div>
          </div>
          <div className="loyalty_card_inner">
            <div className="loyalty_card_blocks">
              <div
                className="loyalty_card_block"
                data-bs-toggle={`${data + 1 === 1 && "modal"}`}
                data-bs-target={`${data + 1 === 1 && "#exampleModal"}`}
                onClick={() => {
                  setLoyalty(1);
                }}
              >
                <Link className={`${data >= 1 && "activated"}`}>1</Link>
              </div>
              <div
                className="loyalty_card_block"
                data-bs-toggle={`${data + 1 === 2 && "modal"}`}
                data-bs-target={`${data + 1 === 2 && "#exampleModal"}`}
                onClick={() => {
                  setLoyalty(2);
                }}
              >
                <Link className={`${data >= 2 && "activated"}`}>2</Link>
              </div>
              <div
                className="loyalty_card_block"
                data-bs-toggle={`${data + 1 === 3 && "modal"}`}
                data-bs-target={`${data + 1 === 3 && "#exampleModal"}`}
                onClick={() => {
                  setLoyalty(3);
                }}
              >
                <Link className={`${data >= 3 && "activated"}`}>3</Link>
              </div>
              <div
                className="loyalty_card_block"
                data-bs-toggle={`${data + 1 === 4 && "modal"}`}
                data-bs-target={`${data + 1 === 4 && "#exampleModal"}`}
                onClick={() => {
                  setLoyalty(4);
                }}
              >
                <Link className={`${data >= 4 && "activated"}`}>4</Link>
              </div>
              <div
                className="loyalty_card_block"
                data-bs-toggle={`${data + 1 === 5 && "modal"}`}
                data-bs-target={`${data + 1 === 5 && "#exampleModal"}`}
                onClick={() => {
                  setLoyalty(5);
                }}
              >
                <Link className={`${data >= 5 && "activated"}`}>5</Link>
              </div>
              <div
                className="loyalty_card_block"
                data-bs-toggle={`${data + 1 === 6 && "modal"}`}
                data-bs-target={`${data + 1 === 6 && "#exampleModal"}`}
                onClick={() => {
                  setLoyalty(6);
                }}
              >
                <Link className={`${data >= 6 && "activated"}`}>6</Link>
              </div>
              <div
                className="loyalty_card_block"
                data-bs-toggle={`${data + 1 === 7 && "modal"}`}
                data-bs-target={`${data + 1 === 7 && "#exampleModal"}`}
                onClick={() => {
                  setLoyalty(7);
                }}
              >
                <Link className={`${data >= 7 && "activated"}`}>7</Link>
              </div>
              <div
                className="loyalty_card_block"
                data-bs-toggle={`${data + 1 === 8 && "modal"}`}
                data-bs-target={`${data + 1 === 8 && "#exampleModal"}`}
                onClick={() => {
                  setLoyalty(8);
                }}
              >
                <Link className={`${data >= 8 && "activated"}`}>8</Link>
              </div>
              <div
                className="loyalty_card_block"
                data-bs-toggle={`${data + 1 === 9 && "modal"}`}
                data-bs-target={`${data + 1 === 9 && "#exampleModal"}`}
                onClick={() => {
                  setLoyalty(9);
                }}
              >
                <Link className={`${data >= 9 && "activated"}`}>9</Link>
              </div>
              <div
                className="loyalty_card_block"
                data-bs-toggle={`${data + 1 === 10 && "modal"}`}
                data-bs-target={`${data + 1 === 10 && "#exampleModal"}`}
                onClick={() => {
                  setLoyalty(10);
                }}
              >
                <Link className={`${data >= 10 && "activated"}`}>10</Link>
              </div>
            </div>
            <div className="reset_btn text-center mt-5">
              <Link
                className="btn btn_primary"
                onClick={data + 1 === 11 && resetLoyalty}
              >
                RESET{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="loyalty_modal">
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <Link
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <img className="img-fluid" src={Close} alt="img" />
                </Link>
              </div>
              <div className="modal-body">
                <form className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <label htmlFor="inputNote" className="form-label">
                      ENTER CODE *
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputCode"
                      value={code}
                      onChange={(e) => {
                        setCode(e.target.value);
                      }}
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <Link
                  type="submit"
                  className="btn btn_secondary"
                  data-bs-dismiss="modal"
                  onClick={saveLoyalty}
                >
                  ok
                </Link>
                <Link
                  type="button"
                  className="btn btn_primary"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loyalty;
