import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./News.scss";
import Arrow from "../../../assets/images/icons/arrow-right-long-solid.svg";
import axios from "axios";

const News = () => {
  const [newsData, setNewsData] = useState([]);
  const getNewsData = async () => {
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("session_user_id"));
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const school_id = localStorage.getItem("session_school_id");
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getNewsBySchoolId&school_id=${school_id}&status=1`;
    await axios.post(api, formData).then((resp) => {
      setNewsData(resp.data);
    });
  };

  useEffect(() => {
    getNewsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="news_listing_section">
      <div className="container-fluid">
        <div className="user_detail">
          <div className="user_detail">
            <div className="news_listing_title">
              <h2>Latest News</h2>
            </div>
          </div>
        </div>

        <div className="news_listing_inner">
          <div className="news_listing_cards row">
            {newsData?.map((item, index) => (
              <div
                key={index}
                className="news_listing_card_column col-xxl-6 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
              >
                <div className="news_listing_card">
                  <div className="news_listing_card_inner">
                    <div className="news_listing_img">
                      <img
                        className="img-fluid"
                        src={item?.school_news_image}
                        alt=""
                      />
                    </div>
                    <div className="news_listing_date_title">
                      <div className="news_listing_title">
                        <h3>{item.school_news_title}</h3>
                      </div>
                      <div className="news_listing_des">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item.school_news_description,
                          }}
                        ></p>
                      </div>
                      <div className="news_listing_date">
                        <Link to={`/Home/media/news/${item.school_news_id}`}>
                          <span>
                            {item.school_news_created_date.split(" ")[0]}
                          </span>
                          <span>
                            <img className="img-fluid" src={Arrow} alt="" />
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
