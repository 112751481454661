import { Link } from "react-router-dom";
import "./ChecklistTeacher.scss";
import vorbereitung from "../../../../assets/images/icons/vorbereitung.svg";
import abschluss from "../../../../assets/images/icons/abschluss.svg";

const ChecklistTeacher = () => {
  return (
    <div className="teacher_checklist_section mt-5">
      <div className="container-fluid">
        <div className="teacher_checklists">
          <div className="user_detail">
            <div className="teacher_checklist_title">
              <h2>TEACHER CHECKLIST</h2>
            </div>
          </div>
          <div className="teacher_checklist">
            <div className="teacher_checklist_inner">
              <div className="teacher_checklist_blocks_group">
                <div className="row">
                  <div className="teacher_checklist_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                    <Link
                      className="teacher_checklist_block_inner"
                      to="/Home/profile/teacher/vorbereitung"
                    >
                      <div className="teacher_checklist_block_icon">
                        <img
                          className="img-fluid"
                          src={vorbereitung}
                          alt="vorbereitung"
                        />
                      </div>
                      <h2>Vorbereitung</h2>
                    </Link>
                  </div>

                  <div className="teacher_checklist_block col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-4 col-6">
                    <Link
                      className="teacher_checklist_block_inner"
                      to="/Home/profile/teacher/abschluss"
                    >
                      <div className="teacher_checklist_block_icon">
                        <img
                          className="img-fluid"
                          src={abschluss}
                          alt="abschluss"
                        />
                      </div>
                      <h2>Abschluss</h2>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChecklistTeacher;
