import React, { useEffect, useState } from "react";
import "./AbsenceOverview.scss";
import axios from "axios";

const AbsenceOverview = () => {
  const [absenceData, setAbsenceData] = useState([]);

  const getAbsenceData = async () => {
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("session_user_id"));

    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getabsentlist&status=1`;

    await axios.post(api, formData).then((resp) => {
      setAbsenceData(resp.data.Absent);
    });
  };

  useEffect(() => {
    getAbsenceData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="overview_absence_section">
      <div className="container-fluid">
        <div className="user_detail">
          <div className="overview_absence_title">
            <h2>Absence</h2>
          </div>
        </div>
        <div className="overview_absence">
          <div className="overview_absence_inner">
            <div className="overview_absence_cards row">
              {absenceData?.map((item, index) => (
                <div
                  key={index}
                  className="overview_absence_card col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                >
                  <div className="overview_absence_card_inner">
                    <div className="form-check overview_absence_card_title">
                      <h3>
                        <span>{item.attendance_absent_start_date}</span> TO{" "}
                        <span>{item.attendance_absent_end_date}</span>
                      </h3>
                    </div>
                    <div className="overview_absence_detail">
                      <p>{item.course_title}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AbsenceOverview;
