import React, { useEffect, useState } from "react";
import "./Message.scss";
import { Link } from "react-router-dom";
import deleteIcon from "../../../assets/images/icons/delete.svg";
import clock from "../../../assets/images/icons/clock.svg";
import calendar from "../../../assets/images/icons/calendar.svg";
import plus from "../../../assets/images/icons/plus_thame_color.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";

const Message = () => {
  const [messageData, setMessageData] = useState([]);
  const [messageToDelete, setMessageToDelete] = useState([]);

  const getNotificationsData = async () => {
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("session_user_id"));

    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getAdminMsgs&user_id=${login_user_id}&status=1`;
    await axios.post(api, formData).then((resp) => {
      resp.data.status !== false
        ? setMessageData(resp.data)
        : setMessageData([]);
    });
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showDetail, setShowDetail] = useState(false);
  const [showDetailData, setShowDetailData] = useState({});

  const handleCloseShowDetail = () => setShowDetail(false);
  const handleShowDetail = () => setShowDetail(true);
  const deleteNotification = async () => {
    messageToDelete?.map(async (item) => {
      const login_cookie = localStorage.getItem("session_user_login_cookie");
      const login_user_id = localStorage.getItem("session_user_id");
      const login_action_type = localStorage.getItem("session_user_type");
      const deleteNotificationApi = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=deleteNotification&user_id=${login_user_id}&user_type=${login_action_type}&notificationIds=${item}&type=message&status=1`;
      await axios.post(deleteNotificationApi).then((resp) => {
        setMessageToDelete([]);
        toast("Message Deleted Successfully");
        getNotificationsData();
      });
    });
  };

  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const shareMessage = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");

    const formData = new FormData();
    formData.append("subject", subject);
    formData.append("compose_email", message);
    const deleteNotificationApi = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=setAdminMsgs&send_id=${login_user_id}&send_user=${login_action_type}&receiver_id=2&receiver_user=School&user_school_parent_id=${localStorage.getItem(
      "session_user_school_parent_id"
    )}&status=1`;
    await axios.post(deleteNotificationApi, formData).then((resp) => {
      toast("Message Shared Successfully");
      setSubject("");
      setMessage("");
      handleClose();
      getNotificationsData();
    });
  };

  const [name, setName] = useState("");

  const getName = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const login_action_user_type = localStorage.getItem(
      "session_school_login_type"
    );
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getlogininfo&user_id=${login_user_id}&action_type=${login_action_user_type}&status=1`;

    await axios.post(api).then((resp) => {
      setName(resp.data);
    });
  };

  useEffect(() => {
    getName();
    getNotificationsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="notifications_section">
      <div className="container-fluid">
        <div className="user_detail">
          <div className="notifications_title">
            <h2>Message</h2>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Compose Message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <label htmlFor="inputFirstname" className="form-label">
                  From
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="inputFrom"
                  value={`To : ${name?.user_firstname?.toUpperCase() || " "} ${
                    name?.user_lastname?.toUpperCase() || " "
                  }`}
                  readOnly
                />
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                <label htmlFor="inputFirstname" className="form-label">
                  To
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="inputTo"
                  value={`To : ${name?.user_school_name?.toUpperCase() || " "}`}
                  readOnly
                />
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                <label htmlFor="inputSubject" className="form-label">
                  Subject
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="inputMessage"
                  value={subject}
                  onChange={(e) => {
                    setSubject(e.target.value);
                  }}
                />
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                <label htmlFor="inputFirstname" className="form-label">
                  Message
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="inputFirstname"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                />
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              className="w-100"
              onClick={() => shareMessage()}
            >
              Submit Message
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showDetail} onHide={handleCloseShowDetail}>
          <Modal.Header closeButton>
            <Modal.Title>Message Detail</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="notifications_title_datetime_inner">
              <div className="notifications_title col-12">
                <h2>
                  FROM :{" "}
                  {showDetailData?.mail_send_user === "School"
                    ? "School Admin"
                    : "Me"}
                </h2>
              </div>
              <div className="notifications_datetime col-12">
                <ul>
                  <li>
                    <img className="img-fluid" src={calendar} alt="calendar" />
                    {showDetailData?.mail_creation_date}
                  </li>
                  <li>
                    <img className="img-fluid" src={clock} alt="clock" />
                    {showDetailData?.mail_creation_date}
                  </li>
                </ul>
              </div>
              <div className="notifications_des col-12">
                <p
                  dangerouslySetInnerHTML={{
                    __html: showDetailData?.mail_body,
                  }}
                ></p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <div className="notifications_listing">
          <div className="notifications_inner">
            <div className="notifications_btn">
              <div className="select_all_btn">
                <div className="select_btn">
                  <Link
                    className="btn btn_primary"
                    onClick={() => {
                      setMessageToDelete([]);
                    }}
                  >
                    UNSELECT
                  </Link>
                </div>
                <div className="all_btn">
                  <Link
                    className="btn btn_primary"
                    onClick={() => {
                      setMessageToDelete([]);
                      setMessageToDelete(
                        messageData?.map((item) => item.mail_id)
                      );
                    }}
                  >
                    SELECT ALL
                  </Link>
                </div>
              </div>
              <div className="delete_btn_icon">
                <div className="delete_btn">
                  <Link onClick={() => handleShow()}>
                    <img src={plus} alt="deleteIcon" />
                  </Link>
                </div>
                <div className="delete_btn">
                  <Link onClick={() => deleteNotification()}>
                    <img src={deleteIcon} alt="deleteIcon" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="notifications_cards row">
              {messageData?.map((item, index) => (
                <div className="notifications_card col-12" key={index}>
                  <div className="notifications_card_inner">
                    <div className="checkbox_title_datetime">
                      <div className="notifications_checkbox">
                        <form>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="gridCheck"
                              checked={
                                messageToDelete.includes(item.mail_id) && true
                              }
                              onChange={() => {
                                !messageToDelete.includes(item.mail_id)
                                  ? setMessageToDelete([
                                      ...messageToDelete,
                                      item.mail_id,
                                    ])
                                  : setMessageToDelete(
                                      messageToDelete.filter(function (value) {
                                        return value !== item.mail_id;
                                      })
                                    );
                              }}
                            />
                          </div>
                        </form>
                      </div>
                      <Link
                        onClick={() => {
                          setShowDetailData(item);
                          handleShowDetail();
                        }}
                        className="notifications_title_datetime"
                      >
                        <div className="notifications_title_datetime_inner">
                          <div className="notifications_title col-12">
                            <h2>
                              {item.mail_send_user === "School"
                                ? "School Admin"
                                : "Me"}
                            </h2>
                          </div>
                          <div className="notifications_datetime col-12">
                            <ul>
                              <li>
                                <img
                                  className="img-fluid"
                                  src={calendar}
                                  alt="calendar"
                                />
                                {item?.mail_creation_date.slice(0, 11)}
                              </li>
                              <li>
                                <img
                                  className="img-fluid"
                                  src={clock}
                                  alt="clock"
                                />
                                {item?.mail_creation_date.slice(11)}
                              </li>
                            </ul>
                          </div>
                          <div className="notifications_des col-12">
                            <p>{item?.mail_subject}</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Message;
