import React from "react";
import "./CourseOverviewTab.scss";
import OverviewCourse from "../OverviewCourse/OverviewCourse";

const CourseOverviewTab = () => {
  return (
    <>
      <OverviewCourse />
    </>
  );
};

export default CourseOverviewTab;
