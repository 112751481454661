import React, { useEffect, useState } from "react";
import "./Notification.scss";
import { Link } from "react-router-dom";
import deleteIcon from "../../../assets/images/icons/delete.svg";
import clock from "../../../assets/images/icons/clock.svg";
import calendar from "../../../assets/images/icons/calendar.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

const Notification = () => {
  const [notificationData, setNotificationsData] = useState([]);
  const [notificationToDelete, setNotificationToDelete] = useState([]);

  const getNotificationsData = async () => {
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("session_user_id"));

    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getAllPushNotification&user_id=${login_user_id}&user_type=${login_action_type}&status=1`;
    await axios.post(api, formData).then((resp) => {
      resp.data.status !== false
        ? setNotificationsData(resp.data)
        : setNotificationsData([]);
    });
  };

  const deleteNotification = async () => {
    notificationToDelete?.map(async (item) => {
      const login_cookie = localStorage.getItem("session_user_login_cookie");
      const login_user_id = localStorage.getItem("session_user_id");
      const login_action_type = localStorage.getItem("session_user_type");
      const deleteNotificationApi = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=deleteNotification&user_id=${login_user_id}&user_type=${login_action_type}&notificationIds=${item}&type=undefined&status=1`;
      await axios.post(deleteNotificationApi).then((resp) => {
        setNotificationToDelete([]);
        toast("Notification Deleted Successfully");
        getNotificationsData();
      });
    });
  };

  const [showDetail, setShowDetail] = useState(false);
  const [showDetailData, setShowDetailData] = useState({});

  const handleCloseShowDetail = () => setShowDetail(false);
  const handleShowDetail = () => setShowDetail(true);

  useEffect(() => {
    getNotificationsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="notifications_section">
      <div className="container-fluid">
        <div className="user_detail">
          <div className="notifications_title">
            <h2>NOTIFICATIONS</h2>
          </div>
        </div>
        <Modal show={showDetail} onHide={handleCloseShowDetail}>
          <Modal.Header closeButton>
            <Modal.Title>Notification Detail</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="notifications_title_datetime_inner">
              <div className="notifications_title col-12">
                <h2>SALSA : {showDetailData?.notification_title}</h2>
              </div>
              <div className="notifications_datetime col-12">
                <ul>
                  <li>
                    <img className="img-fluid" src={calendar} alt="calendar" />
                    {showDetailData?.notification_created_datetime}
                  </li>
                  <li>
                    <img className="img-fluid" src={clock} alt="clock" />
                    {showDetailData?.notification_created_datetime}
                  </li>
                </ul>
              </div>
              <div className="notifications_des col-12">
                <p>{showDetailData?.notification_message}</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <div className="notifications_listing">
          <div className="notifications_inner">
            <div className="notifications_btn">
              <div className="select_all_btn">
                <div className="select_btn">
                  <Link
                    className="btn btn_primary"
                    onClick={() => {
                      setNotificationToDelete([]);
                    }}
                  >
                    UNSELECT
                  </Link>
                </div>
                <div className="all_btn">
                  <Link
                    className="btn btn_primary"
                    onClick={() => {
                      setNotificationToDelete([]);
                      setNotificationToDelete(
                        notificationData?.map((item) => item.notification_id)
                      );
                    }}
                  >
                    SELECT ALL
                  </Link>
                </div>
              </div>
              <div className="delete_btn_icon">
                <div className="delete_btn">
                  <Link onClick={() => deleteNotification()}>
                    <img src={deleteIcon} alt="deleteIcon" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="notifications_cards row">
              {notificationData?.map((item, index) => (
                <div className="notifications_card col-12" key={index}>
                  <div className="notifications_card_inner">
                    <div className="checkbox_title_datetime">
                      <div className="notifications_checkbox">
                        <form>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="gridCheck"
                              checked={
                                notificationToDelete.includes(
                                  item.notification_id
                                ) && true
                              }
                              onChange={() => {
                                !notificationToDelete.includes(
                                  item.notification_id
                                )
                                  ? setNotificationToDelete([
                                      ...notificationToDelete,
                                      item.notification_id,
                                    ])
                                  : setNotificationToDelete(
                                      notificationToDelete.filter(function (
                                        value
                                      ) {
                                        return value !== item.notification_id;
                                      })
                                    );
                              }}
                            />
                          </div>
                        </form>
                      </div>
                      <Link
                        onClick={() => {
                          setShowDetailData(item);
                          handleShowDetail();
                        }}
                        className="notifications_title_datetime"
                      >
                        <div className="notifications_title_datetime_inner">
                          <div className="notifications_title col-12">
                            <h2>SALSA : {item.notification_title}</h2>
                          </div>
                          <div className="notifications_datetime col-12">
                            <ul>
                              <li>
                                <img
                                  className="img-fluid"
                                  src={calendar}
                                  alt="calendar"
                                />
                                {item.notification_created_datetime.slice(
                                  0,
                                  11
                                )}
                              </li>
                              <li>
                                <img
                                  className="img-fluid"
                                  src={clock}
                                  alt="clock"
                                />
                                {item.notification_created_datetime.slice(11)}
                              </li>
                            </ul>
                          </div>
                          <div className="notifications_des col-12">
                            <p>{item.notification_message}</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
