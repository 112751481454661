import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./BeHappy.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const BeHappy = () => {
  const [courseToogle, setCourseToogle] = useState(false);
  const [courseValue, setCourseValue] = useState(null);
  const [name, setName] = useState("");
  const [course, setCourse] = useState(null);
  const [change, setChange] = useState("");
  const [discount, setDisount] = useState("");
  const [reason, setReason] = useState("");
  const [extend, setExtend] = useState("");
  const [review, setReview] = useState("");

  const submitData = (e) => {
    e.preventDefault();

    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("session_user_id"));
    formData.append(
      "data",
      `name=${name}&course=${course}&change=${change}&discount=${discount}&reason=${reason}&extend=${extend}&review=${review}`
    );

    if (
      name === "" ||
      course === null ||
      change === "" ||
      discount === "" ||
      reason === "" ||
      extend === "" ||
      review === ""
    ) {
      toast("All Feilds Are Mandatory");
    } else {
      axios
        .post(
          `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=beHappy_submitcanceldata&status=1`,
          formData
        )
        .then((resp) => {
          if (resp.status) {
            toast("Your Be Happy Form Submited Successfully");
            setName("");
            setCourse(null);
            setCourseValue(null);
            setChange("");
            setDisount("");
            setReason("");
            setExtend("");
            setReview("");
          }
        });
    }
  };

  const [courseData, setCourseData] = useState(null);

  const getDetail = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("session_user_id"));
    formData.append("user_type", localStorage.getItem("session_user_type"));
    formData.append("school_id", localStorage.getItem("session_school_id"));
    axios
      .post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getstudentcourse&status=1`,
        formData
      )
      .then((resp) => {
        setCourseData(resp.data.c1);
      });
  };

  const getName = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const login_action_user_type = localStorage.getItem(
      "session_school_login_type"
    );
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getlogininfo&user_id=${login_user_id}&action_type=${login_action_user_type}&status=1`;

    await axios.post(api).then((resp) => {
      setName(
        `${resp.data.user_firstname?.toUpperCase()} ${resp.data.user_lastname?.toUpperCase()}`
      );
    });
  };

  useEffect(() => {
    getName();
    getDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="be_happy_section">
      <ToastContainer />
      <div className="container-fluid">
        <div className="be_happy_title">
          <h2>DON'T WORRY BE HAPPY</h2>
        </div>
        <div className="be_happy_card">
          <div className="be_happy_card_inner">
            <div className="be_happy_form">
              <form className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <label htmlFor="inputFirstname" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputFirstname"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <label htmlFor="inputFirstname" className="form-label">
                    Current Course
                  </label>
                  <div className="custom-select">
                    <button
                      className={`btn dropdown-toggle ${
                        courseToogle && "show"
                      }`}
                      type="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => {
                        setCourseToogle(!courseToogle);
                      }}
                    >
                      {courseValue === null ? "Select" : courseValue}
                    </button>
                    <ul className={`dropdown-menu ${courseToogle && "show"}`}>
                      {courseData !== null &&
                        Object.values(courseData).map((item, index) => (
                          <li key={index}>
                            <Link
                              className="dropdown-item"
                              onClick={() => {
                                setCourseValue(item.course_title);
                                setCourse(item.course_id);
                                setCourseToogle(false);
                              }}
                              to="#"
                            >
                              {item.course_title}
                            </Link>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <label htmlFor="inputChange" className="form-label">
                    Was hat dir nicht gepasst, möchtest du ändern?:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputChange"
                    value={change}
                    onChange={(e) => {
                      setChange(e.target.value);
                    }}
                  />
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <label htmlFor="inputDiscount" className="form-label">
                    Willst du einen Rabatt? Falls Ja, wie viel wäre deiner
                    Meinung nach angebracht?:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputDiscount"
                    value={discount}
                    onChange={(e) => {
                      setDisount(e.target.value);
                    }}
                  />
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <label htmlFor="inputReason" className="form-label">
                    Begründung:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputReason"
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                  />
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <label htmlFor="inputExtend" className="form-label">
                    Wärst du bereit einen Kurs zu verlängern? Falls Ja, für wie
                    lange?:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputExtend"
                    value={extend}
                    onChange={(e) => {
                      setExtend(e.target.value);
                    }}
                  />
                </div>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <label htmlFor="inputReview" className="form-label">
                    Wärst du bereit eine positive Kundenbewertung zu machen?:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputReview"
                    value={review}
                    onChange={(e) => {
                      setReview(e.target.value);
                    }}
                  />
                </div>
              </form>

              <div className="greetings">
                <p>Vielen Dank für deine Offenheit!</p>
                <p>
                  Wir sind uns sicher, dass wir eine passende Lösung für dich
                  finden werden.
                </p>
                <p>
                  Deine Anfrage wird innerhalb von 24h bearbeitet (Werktage).
                </p>
                <p>Liebe Grüsse</p>
                <p>Dein Salsa People Team</p>
                <p className="mt-5">
                  Die Zeit zum Glücklichsein ist jetzt, und der Ort zum
                  Glücklichsein ist hier
                </p>
              </div>

              <div className="submit_btn">
                <Link className="btn btn_primary" onClick={submitData}>
                  Submit
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeHappy;
