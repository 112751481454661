import React, { useEffect, useState } from "react";
import "./CoupleRequest.scss";
import { Link } from "react-router-dom";
import clock from "../../../assets/images/icons/clock.svg";
import calendar from "../../../assets/images/icons/calendar.svg";
import axios from "axios";
import { toast } from "react-toastify";

const CoupleRequest = () => {
  const [coupleRequests, setCoupleRequests] = useState([]);

  const getAllCoupleRequests = async () => {
    const login_user_id = localStorage.getItem("session_user_id");
    // const login_user_id = 227;
    const coupleRequestsApi = `https://www.api.salsaworldstandardclass.com/function.php/?f=getcoupleCourse&status=1&user_id=${login_user_id}`;
    await axios.post(coupleRequestsApi).then((resp) => {
      setCoupleRequests(resp.data);
    });
  };

  const coupleReqAccept = async (student_id, partner_id, id) => {
    const login_user_id = localStorage.getItem("session_user_id");
    const coupleRequestAcceptApi = `https://www.api.salsaworldstandardclass.com/function.php/?f=acceptCoupleRequest&status=1&user_id=${login_user_id}&student_id=${student_id}&partner_id=${partner_id}&id=${id}`;
    await axios.post(coupleRequestAcceptApi).then((resp) => {
      if (resp.data.status === true) {
        toast("Couple Request Accepted");
        getAllCoupleRequests();
      }
    });
  };

  const coupleReqReject = async (student_id, partner_id, id) => {
    const login_user_id = localStorage.getItem("session_user_id");
    const coupleRequestRejectApi = `https://www.api.salsaworldstandardclass.com/function.php/?f=rejectCoupleRequest&status=1&user_id=${login_user_id}&student_id=${student_id}&partner_id=${partner_id}&id=${id}`;
    await axios.post(coupleRequestRejectApi).then((resp) => {
      if (resp.data.status === true) {
        toast("Couple Request Rejected");
        getAllCoupleRequests();
      }
    });
  };

  useEffect(() => {
    getAllCoupleRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="notifications_section">
      <div className="container-fluid">
        <div className="user_detail">
          <div className="notifications_title">
            <h2>COUPLE REQUESTS</h2>
          </div>
        </div>
        <div className="notifications_listing">
          <div className="notifications_inner">
            <div className="notifications_cards row">
              {coupleRequests?.map((request, index) => (
                <div className="notifications_card d col-12" key={index}>
                  <div className="notifications_card_inner">
                    <div className="checkbox_title_datetime">
                      <Link className="notifications_title_datetime">
                        <div className="notifications_title_datetime_inner">
                          <div className="notifications_title col-12">
                            <h2>{request.course_title}- title</h2>
                          </div>
                          <div className="notifications_datetime col-12">
                            <ul>
                              <li>
                                <img
                                  className="img-fluid"
                                  src={calendar}
                                  alt="calendar"
                                />
                                {request?.created_date?.slice(0, 11)}
                              </li>
                              <li>
                                <img
                                  className="img-fluid"
                                  src={clock}
                                  alt="clock"
                                />
                                {request?.created_date?.slice(11)}
                              </li>
                            </ul>
                          </div>
                          <div className="notifications_des col-12">
                            <p>
                              {request?.student_first_name}{" "}
                              {request?.student_last_name} sent you a couple
                              request. Would you like to Accept or Reject?
                            </p>
                          </div>
                          <div className="mt-4">
                            <button
                              className="btn btn-light"
                              onClick={() =>
                                coupleReqAccept(
                                  request.student_id,
                                  request.partner_id,
                                  request.id
                                )
                              }
                            >
                              Accept
                            </button>
                            <button
                              className="btn ms-3 text-white border-0"
                              style={{ backgroundColor: "#CF2856" }}
                              onClick={() =>
                                coupleReqReject(
                                  request.student_id,
                                  request.partner_id,
                                  request.id
                                )
                              }
                            >
                              Reject
                            </button>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoupleRequest;
