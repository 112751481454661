import React, { useEffect, useState } from "react";
import "./Language.scss";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const Language = () => {
  const [languageToogle, setLanguageToogle] = useState(false);
  const [languageValue, setLanguageValue] = useState(null);
  const navigate = useNavigate();

  const [langCode, setLangCode] = useState("");
  console.log(languageValue);

  const getCurrentLanguage = () => {
    setLangCode(localStorage.getItem("session_language"));
  };

  const checkLan = () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");

    axios
      .post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=load_languages&language=${langCode}&status=1`
      )
      .then((resp) => {
        localStorage.setItem("session_language", langCode);
        toast("Language Changed Successfully");
        setTimeout(() => {
          navigate("/Home/profile");
        }, 3000);
      });
  };

  useEffect(() => {
    getCurrentLanguage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="language_section">
      <ToastContainer />
      <div className="container-fluid">
        <div className="language_title">
          <h2>LANGUAGE</h2>
        </div>
        <div className="language_inner">
          <div className="language_card">
            <div className="language_selector">
              <div className="custom_lable">
                <p>Language</p>
              </div>
              <div className="custom-select">
                <button
                  className={`btn dropdown-toggle ${languageToogle && "show"}`}
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => {
                    setLanguageToogle(!languageToogle);
                  }}
                >
                  {langCode === ""
                    ? "Choose language"
                    : langCode === "en"
                    ? "English"
                    : "German"}
                </button>
                <ul className={`dropdown-menu ${languageToogle && "show"}`}>
                  <li
                    onClick={() => {
                      setLanguageValue("English");
                      setLangCode("en");
                      setLanguageToogle(false);
                    }}
                  >
                    <Link className="dropdown-item" to="#">
                      English
                    </Link>
                  </li>
                  <li
                    onClick={() => {
                      setLanguageValue("German");
                      setLangCode("de");
                      setLanguageToogle(false);
                    }}
                  >
                    <Link className="dropdown-item" to="#">
                      German
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="language_btn">
                <Link
                  className="btn btn_primary"
                  onClick={() => {
                    checkLan();
                  }}
                >
                  next
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Language;
