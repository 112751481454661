import axios from "axios";
import React, { useEffect, useState } from "react";
import red_circle from "../../../../assets/images/icons/red-circle.png";
import "./MyTask.scss";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const MyTask = () => {
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  const taskClose = async (task_id) => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");

    const formData = new FormData();
    formData.append("task_id", task_id);
    formData.append("type", "status_check");
    formData.append("value", "closed");
    formData.append("action_type", "salsa_task");
    await axios
      .post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=changetask&status=1`,
        formData
      )
      .then((resp) => {
        toast("Task Closed");
      });
  };

  const taskDetails = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const user_id = localStorage.getItem("session_user_id");

    const formData = new FormData();
    formData.append("user_id", user_id);

    const modifyImage = () => {
      const images = document.querySelectorAll("td img");
      images.forEach((element) => {
        element.src = red_circle;
      });
    };

    const modifyBtn = () => {
      const images = document.querySelectorAll(".attendsmn a");
      const allId = document.querySelectorAll(
        ".table-group-divider tr .check_admin"
      );
      const idData = [];
      allId.forEach((element) => {
        idData.push(element.getAttribute("data-id"));
      });
      images.forEach((element, index) => {
        element.className = "btn btn_primary";
        element.removeAttribute("onclick");
        element.removeAttribute("style");
        if (element.innerHTML === "View Notes") {
          element.addEventListener("click", function () {
            navigate(`/Home/profile/viewNotes/${idData[index]}`);
          });
        } else {
          element.addEventListener("click", function () {
            navigate(`/Home/profile/processList/${idData[index]}`);
          });
        }
      });
    };

    const closeBtn = () => {
      const close_btn = document.querySelectorAll(".check_status");
      const allId = document.querySelectorAll(
        ".table-group-divider tr .check_admin"
      );
      const idData = [];
      allId.forEach((element) => {
        idData.push(element.getAttribute("data-id"));
      });
      close_btn.forEach((element, index) => {
        element.addEventListener("click", async function () {
          taskClose(idData[index]);
        });
      });
    };

    await axios
      .post(
        `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getTaskNew&status=1`,
        formData
      )
      .then((resp) => {
        setData(resp.data);
        modifyImage();
        modifyBtn();
        closeBtn();
      });
  };

  useEffect(() => {
    taskDetails();
  }, [data]);

  return (
    <div className="task_section">
      <div className="container-fluid">
        <div className="user_detail">
          <div className="task_title">
            <h2>My TASKS</h2>
          </div>
        </div>
        <div className="task_inner">
          <div className="task_table">
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Task Name</th>
                    <th>Responsible Teacher</th>
                    <th>Student</th>
                    <th>Repeat</th>
                    <th>Notes</th>
                    <th>Status</th>
                    <th>Deadline Date</th>
                    <th>Warning Date</th>
                    <th>Telephone Detail</th>
                    <th>Quality check</th>
                    <th>Completed By Teacher</th>
                    <th>Quality status</th>
                    <th>CEO status</th>
                    <th>Quality Reject count</th>
                    <th>CEO Reject count</th>
                    <th>Close Task?</th>
                    <th>Task Process</th>
                    <th>Notes</th>
                  </tr>
                </thead>
                <tbody
                  className="table-group-divider"
                  dangerouslySetInnerHTML={{ __html: data }}
                ></tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyTask;
