import axios from "axios";
import React, { useEffect, useState } from "react";
import "./CheckedIn.scss";

const CheckedIn = ({ setToogleTop }) => {
  const [data, setData] = useState({});
  const date = new Date();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const formattedDate = `${date.getDate()}-${
    monthNames[date.getMonth()]
  }-${date.getFullYear()}`;

  const getCheckInDetail = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");

    const formData = new FormData();
    formData.append("student_id", login_user_id);
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=showcheckedinData&status=1`;

    await axios.post(api, formData).then((resp) => {
      setData(resp.data);
    });
  };

  const getCheckInAllDetail = async () => {
    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");

    const formData = new FormData();
    formData.append("user_id", login_user_id);
    formData.append("user_type", login_action_type);
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getAllstatus&status=1`;

    await axios.post(api, formData).then((resp) => {
      setToogleTop(!resp.data.openbill_status);
      if (
        resp.data.agb_status === true &&
        resp.data.doc_status === true &&
        resp.data.openbill_status === true &&
        resp.data.openpayment_status === true &&
        resp.data.video_status === true
      ) {
        setToogleTop(false);
      } else {
        setToogleTop(true);
      }
    });
  };

  useEffect(() => {
    getCheckInDetail();
    getCheckInAllDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="chacked_section">
      <div className="container-fluid">
        <div className="user_detail">
          <div className="notifications_title">
            <h2>Checked In ({formattedDate})</h2>
          </div>
        </div>
        <div className="chacked_inner">
          <div className="chacked_para">
            <p>You are checked in this course</p>
          </div>

          {data?.courses?.map((value, index) => (
            <div className="chacked_img" key={index}>
              <p>{value.course_title}</p>
              <img className="img-fluid" src={value.room_img} alt="demo" />
            </div>
          ))}
          {/*data?.document?.doc_status === false && (
            <div className="chacked_para">
              <p>Student document is not uploaded yet</p>
            </div>
          )*/}
          {data?.document?.choreo_status === false && (
            <div className="chacked_para">
              <p>Choreo document is not uploaded yet</p>
            </div>
          )}
          {data?.document?.teacher_status === false && (
            <div className="chacked_para">
              <p>Teacher document is not uploaded yet</p>
            </div>
          )}
          <div className="open_payment">
            <h3>open payment</h3>
            {data?.open_payment?.length > 0 &&
              data?.open_payment?.map((value, index) => (
                <div className="chacked_para" key={`${index}-open_payment`}>
                  <p>Course Title : {value?.course_title}</p>
                  <p>Package : {value?.package}</p>
                  <p>Active Date : {value?.active_date}</p>
                  <p>Expired Date : {value?.expired_date}</p>
                </div>
              ))}
            {data?.open_payment?.length === 0 && <p>No open payment found</p>}
          </div>
          <div className="open_bill">
            <h3>open bill</h3>
            {data?.open_bill?.length > 0 &&
              data?.open_bill?.map((value, index) => (
                <div className="chacked_para" key={`${index}-open_bill`}>
                  <p>Category : {value?.category}</p>
                  <p>Bill Text : {value?.bill_text}</p>
                  <p>Ticket Received : {value?.ticket_received}</p>
                  <p>Price : {value?.price}</p>
                  <p>Bill Status : {value?.bill_status}</p>
                  <p>Created Date : {value?.created_date}</p>
                </div>
              ))}
            {data?.open_bill?.length === 0 && <p>No open bill found</p>}
          </div>
          <div className="chacked_table">
            <div className="row">
              <div className="col-12">
                <div className="table_heading">
                  <h3>Open packages</h3>
                </div>
                <div className="table-responsive-xxl">
                  {data?.open_package?.length === 0 ? (
                    <div>
                      <p colSpan="4">No Records Available</p>
                    </div>
                  ) : (
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">Course Title</th>
                          <th scope="col">Activated Date</th>
                          <th scope="col">Expired Date</th>
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody className="table-group-divider">
                        {data?.open_package?.length > 0 &&
                          data?.open_package?.map((value, index) => (
                            <tr key={`${index}-open_package`}>
                              <td>{value?.course_title}</td>
                              <td>{value?.student_package_activated_date}</td>
                              <td>{value?.student_package_expired_date}</td>
                              <td>{value?.status}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckedIn;
