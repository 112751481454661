import React, { useState } from "react";
import "./TeacherPerformance.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const TeacherPearformance = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [address, setAddress] = useState("");

  const navigate = useNavigate();

  const SubmitData = () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("subject", subject);
    formData.append("action_type", "conatctform");
    formData.append("msg", address);
    formData.append("email", email);

    if (name === "" || email === "" || subject === "" || address === "") {
      toast("All Feilds Are Mandatory");
    } else {
      axios
        .post(
          "https://www.salsaworldstandardclass.com/middle/ajax.php",
          formData
        )
        .then((resp) => {
          navigate("//salsaworldstandardclass.com/en/thank-you");
          setName("");
          setEmail("");
          setSubject("");
          setAddress("");
        })
        .catch((err) => {
          navigate("//salsaworldstandardclass.com/en/thank-you");
        });
    }
  };

  return (
    <div className="teacher_perfomance_section">
      <ToastContainer />
      <div className="container-fluid">
        <div className="personal_info_title">
          <h2>Teacher Pearformance</h2>
        </div>
        <div className="personal_info_card">
          <div className="personal_info_card_inner">
            <div className="info_form">
              <div className="info_form_title">
                <h3>CONTACT US</h3>
              </div>
              <div className="row">
                <div className="col-xl-6 col-12">
                  <label htmlFor="inputName" className="form-label">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputName"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </div>
                <div className="col-xl-6 col-12">
                  <label htmlFor="inputEmail" className="form-label">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="inputEmail"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="inputSubject" className="form-label">
                    Subject
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="inputSubject"
                    placeholder="Enter your subject"
                    value={subject}
                    onChange={(e) => {
                      setSubject(e.target.value);
                    }}
                  />
                </div>
                <div className="col-12">
                  <label htmlFor="inputAddress" className="form-label">
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    id="inputAddress"
                    rows="4"
                    placeholder="Write your message here..."
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  ></textarea>
                </div>
                <div className="col-12">
                  <button className="btn btn_primary" onClick={SubmitData}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherPearformance;
