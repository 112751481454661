import React, { useEffect, useState } from "react";
import "./NewsDetail.scss";
import { useParams } from "react-router-dom";
import axios from "axios";

const NewsDetail = () => {
  const { option2 } = useParams();
  const [newsData, setNewsData] = useState([]);
  const getEventsData = async () => {
    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("session_user_id"));

    const login_cookie = localStorage.getItem("session_user_login_cookie");
    const login_user_id = localStorage.getItem("session_user_id");
    const login_action_type = localStorage.getItem("session_user_type");
    const school_id = localStorage.getItem("session_school_id");
    const api = `https://www.api.salsaworldstandardclass.com/function.php/?login_cookie=${login_cookie}&login_user_id=${login_user_id}&login_action_type=${login_action_type}&f=getNewsBySchoolId&school_id=${school_id}&status=1`;
    await axios.post(api, formData).then((resp) => {
      setNewsData(resp.data);
    });
  };

  useEffect(() => {
    getEventsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="news_detail_section">
      {newsData?.map(
        (item, index) =>
          item.school_news_id === option2 && (
            <div key={index} className="container-fluid">
              <div className="news_detail_inner">
                <div className="date_title">
                  <div className="date">
                    <p>{item.school_news_created_date.split(" ")[0]}</p>
                  </div>
                  <div className="title">
                    <h2>{item.school_news_title}</h2>
                  </div>
                </div>
                <div className="news_detail_img">
                  <img
                    src={item?.school_news_image}
                    alt={item?.school_news_image}
                  />
                </div>
                <div className="news_detail">
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: item.school_news_description,
                    }}
                  ></h3>
                </div>
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default NewsDetail;
