import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./SelectSchool.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import nextIcon from "../../assets/images/icons/arrow-down.svg";
import loginBg from "../../assets/images/logo/Group 1.png";

const SelectSchool = () => {
  const [school, setSchool] = useState(null);
  const [schoolValue, setSchoolValue] = useState(null);
  const [schoolList, setSchoolList] = useState([]);
  const [schoolToogle, setSchoolToogle] = useState(false);

  const navigate = useNavigate();

  const loadLan = async () => {
    await axios
      .get(
        "https://www.api.salsaworldstandardclass.com/function.php/function.php/?login_cookie=null&login_user_id=null&login_action_type=null&f=load_languages&language=en&status=1"
      )
      .then((resp) => {
        localStorage.setItem(
          "session_language_array",
          JSON.stringify(resp.data.constant)
        );
        localStorage.setItem(
          "session_settings",
          JSON.stringify(resp.data.settings)
        );
      });
  };

  const getSchoolList = async () => {
    await axios
      .get(
        "https://www.api.salsaworldstandardclass.com/function.php/function.php/?login_cookie=null&login_user_id=null&login_action_type=null&f=getSchoolList&status=1"
      )
      .then((resp) => {
        setSchoolList(resp.data);
      });
  };

  useEffect(() => {
    loadLan();
    getSchoolList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (localStorage.getItem("session_user_status") === "Active") {
    navigate("/Home/dashboard");
  }

  const nextClick = () => {
    const notifyEmptySchool = () => toast("Please Select School");
    school === null
      ? notifyEmptySchool()
      : localStorage.setItem("session_school_id", school);
  };

  return (
    <>
      <section className="login_section">
        <ToastContainer />
        <div className="container">
          <div className="login_row row align-items-center">
            <div className="login_img_column">
              <div className="login_img">
                <img className="img img-fluid" src={loginBg} alt="mainImg" />
              </div>
            </div>
            <div className="login_content_column">
              <div className="login_form_title">
                <h1>
                  TAKE YOUR DANCING <br /> TO THE NEXT LEVEL
                </h1>
              </div>
              <div className="login_form">
                <div className="custom-select">
                  <button
                    className={`btn dropdown-toggle ${schoolToogle && "show"}`}
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={() => {
                      setSchoolToogle(!schoolToogle);
                    }}
                  >
                    {schoolValue === null ? "Select School" : schoolValue}
                  </button>
                  <ul className={`dropdown-menu ${schoolToogle && "show"}`}>
                    {schoolList?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          setSchool(item.school_id);
                          setSchoolValue(item.school_contact_name);
                        }}
                      >
                        <Link
                          className="dropdown-item"
                          onClick={() => {
                            setSchoolToogle(false);
                          }}
                        >
                          {item.school_contact_name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div
                  className="login_btn"
                  onClick={() => {
                    nextClick();
                  }}
                >
                  <Link
                    to={school === null ? "/" : "/SignIn"}
                    className="btn btn_primary"
                  >
                    <span className="next">Next</span>{" "}
                    <img
                      className="img img-fluid"
                      src={nextIcon}
                      alt="forward iconS"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="copyright">
        <p>© 2023 SALSA WORLD STANDARD CLASS</p>
      </section>
    </>
  );
};

export default SelectSchool;
